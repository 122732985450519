/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_WW2XF29rM",
    "aws_user_pools_web_client_id": "56qnnnff4l1mj9ed37ivseb6ro",
    "oauth": {}
};


export default awsmobile;
