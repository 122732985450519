import React, {useState, useEffect} from 'react';
import InputMask from 'react-input-mask';
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
// toast
import { ToastContainer } from 'react-toastify'; 

let BoletoForm = () => {
  const { register, errors, setValue, getValues, handleSubmit, triggerValidation } = useForm()
  const dispatch = useDispatch();
  const subscriber = useSelector(state => state.subscriber);
  const showModalCode = useSelector(state => state.payment.showModalCode);
  const [modalCode, setModalCode] = useState(true);

  const toggleModalCode = () => {
    setModalCode(!modalCode)
  }

  useEffect(() => {
    dispatch({ type: 'subscriber/setCode', payload: "" });
  }, [])

  const setCodeSubscriber = async (code) => {
    await dispatch({ type: 'subscriber/setCode', payload: code });
  }

  const onSubmit = (values) => {
    dispatch({ type: 'payment/boletoPayment', payload: {boleto: values, subscriber} });
  }

  const values = getValues();
  register({ name: 'cpf' }, { required: 'Este campo deve ser preenchido', pattern: /[0-9.-]{13}/ })
  register({ name: 'cep' }, { required: 'Este campo deve ser preenchido', pattern: /[0-9-]{9}/ })
  register({ name: 'cellphone' }, { required: 'Este campo deve ser preenchido', pattern: /[0-9 ()-]{15}/ })
  return (
    <div id="PaymentForm">

      <ToastContainer 
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Form onSubmit={handleSubmit(onSubmit)}>
      <p className="pt-5">Seus Dados Pessoais: </p>
        <Row>
          <Col md="6" className="pt-3">
            <div className="js-form-message mb-6">
              <label className="form-label">
                Nome Completo *</label>
              <FormGroup className={(values.name !== (undefined || '')) ? (errors.name ? "has-danger" : "has-success") : ''}>
                <Input
                  name="name"
                  placeholder="Seu Nome"
                  required=""
                  type="text"
                  onChange={e => { setValue('name', e.target.value);  triggerValidation("name");}}
                  innerRef={register({
                    required: 'Este campo deve ser preenchido',
                    pattern: /.+\s.+/
                  })}
                />
              </FormGroup>
            </div>
          </Col>
          <Col md="6" className="pt-3">
            <div className="js-form-message mb-6">
              <label className="labels" htmlFor="#firstName">
                Digite o seu CPF *
              </label>
              <FormGroup className={(values.cpf !== (undefined || '')) ? (errors.cpf ? "has-danger" : "has-success") : ''}>
                <InputMask
                  className='form-control'
                  mask="999.999.999-99"
                  placeholder="999.999.999-99"
                  type="tel"
                  name="cpf"
                  onChange={e => { setValue('cpf', e.target.value);  triggerValidation("cpf");}}
                />
              </FormGroup>
            </div>
          </Col>
          <Col md="12" className="pt-3">
            <div className="js-form-message mb-6">
              <label className="labels" htmlFor="#firstName">
                Digite o seu Celular *
              </label>
              <FormGroup className={(values.celphone !== (undefined || '')) ? (errors.celphone ? "has-danger" : "has-success") : ''}>
                <InputMask
                  className='form-control'
                  mask="(99) 99999-9999"
                  placeholder="(99) 99999-9999"
                  type="tel"
                  name="cellphone"
                  onChange={e => { setValue('cellphone', e.target.value);  triggerValidation("cellphone");}}
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
        <p className="pt-5">Seu Endereço: </p>
        <Row >
          <Col md="6" className="pt-3">
            <div className="js-form-message mb-6">
              <label className="form-label">CEP *</label>
              <FormGroup className={(values.cep !== undefined) ? (errors.cep ? "has-danger" : "has-success") : ''}>
              <InputMask
                className='form-control'
                mask="99999-999"
                placeholder="99999-999"
                type="tel"
                name="cep"
                onChange={e => { setValue('cep', e.target.value);  triggerValidation("cep");}}
              />
              </FormGroup>
            </div>
          </Col>
          <Col md="6" className="pt-3">
            <div className="js-form-message mb-6">
              <label className="form-label">Número *</label>
              <FormGroup className={(values.number !== (undefined || '')) ? (errors.number ? "has-danger" : "has-success") : ''}>
                <Input
                  name="number"
                  placeholder="Número"
                  required=""
                  type="text"
                  onChange={e => { setValue('number', e.target.value);  triggerValidation("number");}}
                  innerRef={register({
                    required: 'Este campo deve ser preenchido',
                  })}
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12" className="pt-3">
            <div className="js-form-message mb-6">
              <label className="form-label">Complemento</label>
              <FormGroup className={(values.complement !== (undefined || '')) ? (errors.complement ? "has-danger" : "has-success") : ''}>
                <Input
                  name="complement"
                  placeholder="Número"
                  required=""
                  type="text"
                  innerRef={register()}
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
        { showModalCode && <Button color="secondary" style={{ width: '100%' }} onClick={toggleModalCode}>
          Código enviado no e-mail.
        </Button>}
        <Button
          color="primary"
          style={{ width: '100%' }}
          type="submit"
        >
          Pagar
        </Button>
        </Form>
        { showModalCode && <Modal isOpen={modalCode} toggle={toggleModalCode}>
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={toggleModalCode}>
            <i className="tim-icons icon-simple-remove"></i>
          </button>
          <h5 className="modal-title">Enviamos um código em seu e-mail:</h5>
        </div>
        <ModalBody>
          <Input
            style={{color: "#222a42"}}
            name="code"
            placeholder="Insira seu código aqui"
            type="text"
            value={subscriber.code || ""}
            onChange={e => { setCodeSubscriber( e.target.value); }}
          />
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={toggleModalCode}>
                Salvar e Fechar.
            </Button>
        </ModalFooter>
      </Modal> }
      </div>
  )
}

export default BoletoForm;
