import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
// react css style
import styled from 'styled-components';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.jsx";
import DemoFooter from "components/Footers/DemoFooter.jsx";

import { chartExample1 } from "variables/charts.js";

// styled components
const Icon = styled.i`
  font-size: 2.5rem;
`

class LandingPage extends React.Component {
  videos = ['video.mp4', 'video1.mp4', 'video2.mp4', 'video3.mp4', 'video4.mp4']
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("landing-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("landing-page");
  }
  render() {
    return (
      <>
        <ColorNavbar />
        <div className="header-4">
        <div className="wrapper" ref="wrapper">
          <div className="page-header header-video header-filter">
            <div className="overlay" />
            <video
                autoPlay="autoplay"
                loop="loop"
                muted="muted"
                playsInline="playsinline"
            >
              <source
                  src={"/enjoy_video.mp4"}
                  type="video/mp4"
              />
            </video>
            <Container className="text-center">
              <div className="video-text">
                <h2 className="description">Faça mais com sua audiência!</h2>
                <h1 className="title">Enjoy your fans.</h1>
                <br />
                <Button
                    className="btn-simple btn-neutral"
                    color="default"
                    onClick={e => e.preventDefault()}
                >
                  Cadastre-se aqui!
                </Button>
              </div>
            </Container>
          </div>
        </div>
          <section className="section section-lg">
            <img
              alt="..."
              className="path"
              src={require("assets/img/path4.png")}
            />
            <Container>
              <Row className="justify-content-center">

                  <h1 className="text-center">Soluções</h1>
                  <Row className="row-grid justify-content-center">

                    <Col className="d-flex mt-4 pl-0">
                      <div className="icon icon-primary mr-4">
                        <Icon className="tim-icons icon-bulb-63"/>
                      </div>   
                      <div>
                        <h4 className="info-title">Close Friends</h4>
                        <hr className="line-primary" />
                        <p>
                        O Gerenciamento do Close Friends da Enjoy.Fans adiciona e remove automaticamente seus seguidores da lista exclusiva.
                        O seguidor realiza o pagamento e em até 05 minutos é adicionado na lista de melhores amigos e acompanhar seus stories exclusivos. Em caso de  vencimento ou cancelamento, a remoção também é realizada de forma automática, segura e otimizando seu tempo para o que você sabe fazer de melhor: Criar Conteúdo!
                        </p>                      
                      </div>
                    </Col>


                    <Col className="d-flex mt-4">
                      <div className="icon  mr-4" >
                        <Icon className="tim-icons icon-video-66" style={{ color: "#ffc824"}}/>
                      </div>   
                      <div>
                        <h4 className="info-title">Lives</h4>
                        <hr style={{ backgroundColor: '#ffc824'}}/>
                        <p>
                        Na plataforma Enjoy.Fans você poderá fazer lives exclusivas para a sua lista de melhores amigos ou para eventos específicos, podendo ser diretamente via Instagram ou com link exclusivo, aumentando o engajamento e oferecendo um produto diferenciado ao assinante.
                        </p>                      
                      </div>
                    </Col>

                  <Row>
                    <Col className="d-flex mt-4">
                        <div className="icon icon-info mr-4">
                          <Icon className="tim-icons icon-tap-02"/>
                        </div>   
                        <div>
                          <h4 className="info-title">Arraste pra Cima</h4>
                          <hr className="line-info" />
                          <p>
                          O Instagram tem uma política rígida para conteúdo que não atendem suas diretrizes. Baseado nisso, criamos um ambiente separado, porém integrado ao mesmo banco de dados do Close Friends, não sendo necessária a autenticação do assinante.<br/>
                          Você pode definir preços diferentes aos dois produtos: Close Friends e Arraste pra Cima.<br/>
                          Basta arrastar pra cima!
                          </p>                      
                        </div>
                      </Col>

                      <Col className="d-flex mt-4">
                        <div className="icon icon-success mr-4">
                          <Icon className="tim-icons icon-bag-16"/>
                        </div>   
                        <div>
                          <h4 className="info-title">Venda de Conteúdos Exclusivos</h4>
                          <hr className="line-success" />
                          <p>
                          Voltado para influencers que produzem conteúdo mais produzido, o ambiente do Enjoy.Fans é perfeito para armazenar e comercializar esse conteúdo diferenciado. <br/>
                          O pagamento é independente do Close Friends, aumentando ainda mais seus rendimentos com suas redes sociais.
                          </p>                      
                        </div>
                      </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex mt-4">
                      <div className="icon icon-warning mr-4">
                        <Icon className="tim-icons icon-chart-bar-32"/>
                      </div>   
                      <div>
                        <h4 className="info-title">Relatórios</h4>
                        <hr className="line-warning" />
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur in efficitur diam. Praesent lobortis, dui sit amet maximus rhoncus, quam urna pulvinar nulla, et viverra mauris enim eget orci. Pellentesque suscipit velit vitae magna mattis mattis.
                        </p>                      
                      </div>
                    </Col>

                    <Col className="d-flex mt-4">
                      <div className="icon icon-danger mr-4">
                        <Icon className="tim-icons icon-money-coins"/>
                      </div>   
                      <div>
                        <h4 className="info-title">Formas de Pagamento</h4>
                        <hr className="line-danger" />
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur in efficitur diam. Praesent lobortis, dui sit amet maximus rhoncus, quam urna pulvinar nulla, et viverra mauris enim eget orci. Pellentesque suscipit velit vitae magna mattis mattis.
                        </p>                      
                      </div>
                    </Col>
                  </Row>                  

              </Row>
              </Row>
            </Container>
          </section>
          <section className="section section-lg section-safe">
            <img
              alt="..."
              className="path"
              src={require("assets/img/path5.png")}
            />
            <Container>
              <Row className="row-grid justify-content-between">
                <Col md="5">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/chester-wade.jpg")}
                  />
                  <Card className="card-stats bg-danger">
                    <CardBody>
                      <div className="justify-content-center">
                        <div className="numbers">
                          <CardTitle tag="p">100%</CardTitle>
                          <p className="card-category text-white">Safe</p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="card-stats bg-info">
                    <CardBody>
                      <div className="justify-content-center">
                        <div className="numbers">
                          <CardTitle tag="p">573 K</CardTitle>
                          <p className="card-category text-white">
                            Satisfied customers
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="card-stats bg-default">
                    <CardBody>
                      <div className="justify-content-center">
                        <div className="numbers">
                          <CardTitle tag="p">10 425</CardTitle>
                          <p className="card-category text-white">Business</p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <div className="px-md-5">
                    <hr className="line-success" />
                    <h3>Awesome features</h3>
                    <p>
                      The design system comes with three pre-built pages to help
                      you get started faster. You can change the text and images
                      and you're good to go.
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-vector" />
                          </div>
                          <div className="ml-3">
                            <h6>Carefully crafted components</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-tap-02" />
                          </div>
                          <div className="ml-3">
                            <h6>Amazing page examples</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-single-02" />
                          </div>
                          <div className="ml-3">
                            <h6>Super friendly support team</h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <div className="contactus-3">
            <div className="page-header header-filter">
              <div
                  className="page-header-image"
                  style={{
                    backgroundImage:
                        "url(" + require("assets/img/andre-benz.jpg") + ")"
                  }}
              />
              <Container>
                <Row>
                  <Col className="text-center" md="12">
                    <h1 className="title">Got a question?</h1>
                    <h3>We'd like to talk more about what you need</h3>
                  </Col>
                </Row>
              </Container>
            </div>
            <Container fluid>
              <Row>
                <Col md="3">
                  <div className="info info-hover">
                    <div className="icon icon-primary">
                      <img
                          alt="..."
                          className="bg-blob"
                          src={require("assets/img/feature-blob/primary.png")}
                      />
                      <i className="tim-icons icon-square-pin" />
                    </div>
                    <h4 className="info-title">Address</h4>
                    <p className="description">12124 First Street, nr 54</p>
                  </div>
                </Col>
                <Col md="3">
                  <div className="info info-hover">
                    <div className="icon icon-info">
                      <img
                          alt="..."
                          className="bg-blob"
                          src={require("assets/img/feature-blob/info.png")}
                      />
                      <i className="tim-icons icon-email-85" />
                    </div>
                    <h4 className="info-title">Email</h4>
                    <p className="description">support@youremail.com</p>
                  </div>
                </Col>
                <Col md="3">
                  <div className="info info-hover">
                    <div className="icon icon-warning">
                      <img
                          alt="..."
                          className="bg-blob"
                          src={require("assets/img/feature-blob/warning.png")}
                      />
                      <i className="tim-icons icon-mobile" />
                    </div>
                    <h4 className="info-title">Phone Number</h4>
                    <p className="description">+1(424) 535 3523</p>
                  </div>
                </Col>
                <Col md="3">
                  <div className="info info-hover">
                    <div className="icon icon-success">
                      <img
                          alt="..."
                          className="bg-blob"
                          src={require("assets/img/feature-blob/success.png")}
                      />
                      <i className="tim-icons icon-single-02" />
                    </div>
                    <h4 className="info-title">Contact</h4>
                    <p className="description">Andrew Samian</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default LandingPage;
