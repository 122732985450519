import React, {useEffect, useState} from 'react';
import SubscriberNavbar from "../../../components/Navbars/SubscriberNavbar";
import {Container, Row, Col} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Plan from "./Plan";
import InfluencerCard from "./InfluencerCard";
import Album from './albums/Album'
import { http_with_cognito } from "../../../lib/http";

const PlanDetailed = () => {
    const dispatch = useDispatch();
    const planInitialState = false
    const [ plan, setPlan ] = useState(planInitialState)
    const subscriptions = useSelector(state => state.subscriber.me.subscriptions );
    let { subscriptionId } = useParams();

    const subscription = ( subscriptions || [] ).find( s => s.subscription.id === parseInt(subscriptionId) )

    const getPlan = async () => {
        const planData = await http_with_cognito.get(`subscribers/plan/${subscription.plan.id}`);
        console.log()
        setPlan(planData.data);
    }

    useEffect(() => {
        dispatch({type: 'subscriber/getSubscriber'});
    },[])


    if(subscription && plan === false ){
        getPlan()
    }

    return (
        <>
            <SubscriberNavbar />
            { subscription && <Container style={{paddingTop:  100}}>
                <Row>
                    <Col md={4}>
                        <InfluencerCard subscription={subscription} />
                    </Col>
                    <Col md={8}>
                        <h2>{subscription.plan.name}</h2>
                        {plan.instagram_accounts && <h3>Siga para entrar no close friends: { (plan.instagram_accounts || []).map( i => <a target='_blank' href={ `https://instagram.com/${i}` } >@{i} </a>)}</h3>}
                        { ( plan.albums || []) .map( a => <Album album={a} />)}
                    </Col>
                </Row>
            </Container> }
        </>
    );
};

export default PlanDetailed;
