import React from 'react';
import {
Card, CardHeader, CardBody, CardFooter
} from "reactstrap";
import styled from "styled-components";
import Video from "./Video";

const VideoCollection = styled.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    maxHeight: '520px',
    overflow: 'scroll'
})

const StyledCarFooter = styled(CardFooter)({
    padding: "10px"
})


const Album = ({album}) => {
    return (
        <Card>
            <CardHeader className="section-description">
                <h1 className="title ml-3">{album.name}</h1>
                <h5 className="category text-danger ml-3">
                    <strong>{album.description}</strong>
                </h5>
            </CardHeader>
            <CardBody>
                <VideoCollection className="pt-4 pb-4">
                { album.videos.map( v =>
                    <div key={`video-${Math.random()}`}>
                        <Video video_url={v.urls.video} thumb={v.urls.thumbnail} ></Video>
                    </div>
                )}
                </VideoCollection>
            </CardBody>
        </Card>
    );
};

export default Album;
