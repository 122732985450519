import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Plan from './Plan'
import SubscriberNavbar from "../../../components/Navbars/SubscriberNavbar";
import {Col, Container, Row, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button} from "reactstrap";

const MyAccount = () => {
    const dispatch = useDispatch();
    const subscriptions = useSelector(state => state.subscriber.me.subscriptions )

    useEffect(() => {
        dispatch({type: 'subscriber/getSubscriber'});
    },[])

    return (
        <>
            <SubscriberNavbar />
                    <Container style={{paddingTop:  100}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                        { subscriptions && subscriptions.map( subscription => <Plan subscription={subscription} /> )}
                        </div>
                    </Container>
        </>
    );
};

export default MyAccount;
