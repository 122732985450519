import React, {useEffect} from 'react';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux'
import InputMask from 'react-input-mask';
// reactstrap components
import {
  Card,
  Button,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
  CardHeader,
  CardBody,
  CardFooter, CardImg, CardTitle
} from "reactstrap";
import { useHistory } from 'react-router-dom';
import SearchProfile from '../components/SearchProfile'

export default function InfluencerForm({instagram, instagramImage}) {
  const { handleSubmit, register, control, errors, setValue, getValues, triggerValidation } = useForm();
  const currentInfluencer = useSelector(state => state.influencer.current_influencer)
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = values => {
    console.log("submited form");
    dispatch({ type: 'influencer/register', payload: values });
  };

  useEffect(() => {
    if(currentInfluencer && currentInfluencer.id){
      history.push('/influencer/home')
    }
  })

  const values = getValues();

  console.log(values)
  console.log('errors', errors)

  register({ name: 'cpf' }, { required: 'Este campo deve ser preenchido', pattern: /[0-9\.-]{13}/ });
  register({ name: 'phone' }, { required: 'Este campo deve ser preenchido', pattern: /.{11}[0-9]{4}/ });
  register({ name: 'instagram' }, { required: 'Este campo deve ser preenchido'});
  register({ name: 'image' }, { required: 'Este campo deve ser preenchido'});

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="js-validate" >
      <Card  className="card-login">
        <CardHeader>
          <CardImg
              alt="..."
              src={require("assets/img/square-purple-1.png")}
          />
          <CardTitle tag="h4" style={{color: '#FFF'}}>cadastrar</CardTitle>
        </CardHeader>
        <CardBody>
        <Row>
          <Col md="6">
            <label className="labels">
              Nome Completo <span className="text-danger">*</span>
            </label>
            <FormGroup className={(values.name !== undefined ) ? (errors.name ? "has-danger" : "has-success") : ''}>
                <Input
                  aria-label="Jack Wayley"
                  name="name"
                  placeholder="Seu Nome"
                  required=""
                  type="text"
                  onChange={e => { setValue('name', e.target.value);  triggerValidation("name");}}
                  innerRef={register({
                    required: 'Este campo deve ser preenchido',
                    pattern: /.+\s.+/
                  })}
                />
              </FormGroup>
          </Col>
          <Col md="6">
            <label className="labels">
              CPF <span className="text-danger">*</span>
            </label>
            <FormGroup className={(values.cpf !== (undefined || '')) ? (errors.cpf ? "has-danger" : "has-success") : ''}>
                <InputMask
                  className='form-control'
                  mask="999.999.999-99"
                  placeholder="999.999.999-99"
                  type="tel"
                  name="cpf"
                  onChange={e => { setValue('cpf', e.target.value);  triggerValidation("cpf");}}
                />
              </FormGroup>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md="6">
            <label className="labels">
              Instagram <span className="text-danger">*</span>
            </label>
            <FormGroup className={(values.instagram !== (undefined || '')) ? (errors.instagram ? "has-danger" : "has-success") : ''}>
              <SearchProfile valueChange={i => {
                if (i[0]){
                  setValue('instagram', i[0]['username'] );
                  setValue('image', i[0]['image'] );
                  instagram(i[0]['username']);
                  instagramImage(i[0]['image']);
                }
                else {
                  setValue('instagram', '' );
                  setValue('image', '');
                  instagram('')
                  instagramImage('')
                }
                triggerValidation('instagram');
              }}/>
            </FormGroup>
          </Col>
          <Col md="6">
            <div className="js-form-message mb-6">
              <label className="labels">
              Celular<span className="text-danger">*</span>
              </label>
              <FormGroup className={(values.phone !== (undefined || '')) ? (errors.phone ? "has-danger" : "has-success") : ''}>
                <InputMask
                  className='form-control'
                  mask="(99) 99999-9999"
                  placeholder="(99) 99999-9999"
                  type="tel"
                  name="phone"
                  onChange={e => { setValue('phone', e.target.value);  triggerValidation("phone");}}
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md="6">
            <div className="js-form-message mb-6">
              <label className="labels">
                Email{" "}
                <span className="text-danger">*</span>
              </label>
              <FormGroup className={(values.email !== (undefined || '')) ? (errors.email ? "has-danger" : "has-success") : ''}>
                <Input
                  aria-label="jane@doe.com"
                  name="email"
                  placeholder="jane@doe.com"
                  required=""
                  type="email"
                  innerRef={register({
                    required: 'Este campo deve ser preenchido',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                    }
                  })}
                  onChange={e => { setValue('email', e.target.value);  triggerValidation("email");}}
                />
              </FormGroup>
            </div>
          </Col>
          <Col md="6">
            <div className="js-form-message mb-6">
              <label className="labels">
                Senha{" "}
                <span className="text-danger">*</span>
              </label>
              <FormGroup className={(values.password !== (undefined || '')) ? (errors.password ? "has-danger" : "has-success") : ''}>
              <Input
                aria-label="*******"
                name="password"
                placeholder="*******"
                required=""
                type="password"
                innerRef={register({
                  required: 'Este campo deve ser preenchido',
                  minLength: 6
                })}
                onChange={e => { setValue('password', e.target.value);  triggerValidation("password");}}
              />
              </FormGroup>
            </div>
          </Col>
        </Row>
          <Row>
            <Col md="6">
              <label className="labels">
                Documento de Identidade
                <span className="text-danger">*</span>
              </label>
              <input
                type="file"
                name="identity_file"
                ref={
                  register({
                    required: true
                  })
                }
              />
            </Col>
            <Col md="6">
              <div className="js-form-message mb-6">
                <label className="labels">
                  Confirmação de Senha{" "}
                  <span className="text-danger">*</span>
                </label>
                <FormGroup className={(values.password !== (undefined || '')) ? (errors.password ? "has-danger" : "has-success") : ''}>
                  <Input
                    aria-label="*******"
                    name="password_confirmation"
                    placeholder="*******"
                    required=""
                    type="password"
                    innerRef={register({
                      required: 'Este campo deve ser preenchido'
                    })}
                    onChange={e => { setValue('password_confirmation', e.target.value);  triggerValidation("password_confirmation");}}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>


        </CardBody>
        <CardFooter style={{display: 'inline-grid', padding: '20px'}} >
        <Button className="btn-round" type="submit" color="primary">
          Continuar
        </Button>
        </CardFooter>
      </Card>
    </Form>
  )
}
