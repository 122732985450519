/*!

=========================================================
* BLK Design System PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  Container,
  Row,
  Col, Nav, Button
} from "reactstrap";
import {Auth} from "aws-amplify";

export default function ColorNavbar() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [navbarColor, setNavbarColor] = useState("navbar-transparent");

  const history = useHistory();
  const changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 5 ||
      document.body.scrollTop > 5
    ) {
      setNavbarColor("bg-darker");
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      setNavbarColor("navbar-transparent");
    }
  };

  useEffect(() => {
    const fetchUserSession = async () => {
      try {
        Auth.currentAuthenticatedUser().then(user => {
          setLoggedIn(true);
        }).catch(e => {
          history.push('/');
        })
      } catch (error) {
        history.push('/');
      }
    }
    fetchUserSession()
  }, []);

  async function signOut() {
    try {
      await Auth.signOut();
      history.push('/');
    } catch (error) {
      history.push('/');
    }
  }

  useEffect(() => { window.addEventListener("scroll", changeNavbarColor); }, [])
  return (
    <>
    <Navbar className={"fixed-top " + navbarColor}  style={{marginBottom: 20}}>
      <Container>
        <div className="navbar-translate">
          <NavbarBrand to="/index" tag={Link} id="tooltip6619950104">
          <img height={50} alt="..." src={require("assets/img/enjoy branco.png")} />
          </NavbarBrand>
        { loggedIn &&         <>
          <Button
              className="btn-simple btn-neutral"
              color="default"
              onClick={() => history.push('/my-account')}
              size="sm"
          >Minha Conta
          </Button>
          <Button
              className="btn-simple btn-neutral"
              color="default"
              onClick={() => signOut()}
              size="sm"
          >
            Sair
          </Button>
        </>}
        </div>
      </Container>
    </Navbar>
  </>
  )
}
