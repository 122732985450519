import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from 'react-redux'
import SearchProfile from '../../components/SearchProfile';
import InputMask from 'react-input-mask';
import {
    Button,
    Form,
    Input,
    Container,
    Row,
    Col,
    FormGroup
} from "reactstrap";

// toast
import { ToastContainer } from 'react-toastify';
import InstagramAccountsList from "./InstagramAccountsList";

const InstagramForm = () => {
    const { handleSubmit, errors, getValues, triggerValidation, setValue, register} = useForm();
    register({ name: 'username' }, { required: 'Este campo deve ser preenchido'});
    register({ name: 'image' });

    const dispatch = useDispatch();

    const [instagram, setInstagram] = useState(false)
    const [instagramPassword, setInstagramPassword] = useState('')
    const [searchInstagram, setSearchInstagram] = useState('')

    useEffect(() => {
        dispatch({ type: 'influencer/listInstagramAccounts' })
    }, [])

    useEffect(() => {
        if(searchInstagram[0]){
            setInstagram(searchInstagram[0].username);
            setValue('username', searchInstagram[0].username);
            setValue('image', searchInstagram[0].image);
        }
    }, [searchInstagram]);

    useEffect(() => {
        setValue('password', instagramPassword);
    }, [instagramPassword] )

    const onSubmit = () => {
        const values = getValues();
        dispatch({ type: 'influencer/addInstagramAccount', payload: values })
    };

    const values = getValues();
    console.log('formValuesInstagram', values)

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <Form onSubmit={handleSubmit(onSubmit)} className="js-validate">
                <Container style={{paddingTop: 50}}>
                    <h3 className="title">Close Friends:</h3>
                    <Row>
                        <Col md="6">
                            <label className="labels">
                                Instagram <span className="text-danger">*</span>
                            </label>
                            <FormGroup className={(values.name !== (undefined || '')) ? (errors.name ? "has-danger" : "has-success") : ''}>
                                <SearchProfile valueChange={setSearchInstagram} oldValue={instagram}/>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <label className="labels">
                                Senha: <span className="text-danger">*</span>
                            </label>
                            <FormGroup className={(values.amount !== (undefined || '')) ? (errors.amount ? "has-danger" : "has-success") : ''}>
                                <Input
                                    className='form-control'
                                    placeholder="Senha do instagram..."
                                    required
                                    value={instagramPassword}
                                    name="password"
                                    type='password'
                                    innerRef={register({
                                        required: 'Este campo deve ser preenchido',
                                        minLength: 6
                                    })}
                                    onChange={e => { setInstagramPassword( e.target.value);}}
                                    onBlur={ () => triggerValidation("amount")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button className="btn-round" style={{float:"right"}} type="submit" color="primary">
                        Criar
                    </Button>
                </Container>
            </Form>
            <InstagramAccountsList />
        </>
    );
};

export default InstagramForm;
