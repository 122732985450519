import React, {useEffect, useState} from 'react';
import {
Card, CardHeader, CardBody, CardFooter, Badge, Button
} from "reactstrap";
import styled from "styled-components";
import VideoForm from "./VideoForm";
import Video from "./Video";
import {useDispatch, useSelector} from 'react-redux';

const VideoCollection = styled.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    maxHeight: '520px',
    overflow: 'scroll'
})

const StyledCarFooter = styled(CardFooter)({
    padding: "10px"
})


const Album = ({album}) => {
    const [showForm, setShowForm] = useState(false);
    const plans = useSelector( state => state.influencer.plans );
    const albumPlans = album.plans;
    const dispatch = useDispatch();

    const deleteVideo = (videoId, albumId) => {
        dispatch({type: 'albums/removeVideo', payload: {
                video_id: videoId,
                album_id: albumId
            }})
    }

    return (
        <Card>
            <CardHeader className="section-description">
                <h1 className="title ml-3">{album.name}</h1>
                <h5 className="category text-danger ml-3">
                    <strong>{album.description}</strong>
                </h5>
                <h6 className='ml-3'>clique para ativar ou desativar os planos neste album: <Badge color='success' className="ml-3">ativo</Badge> <Badge color='danger' className="ml-3">inativo</Badge></h6>
                <p className='ml-3 mr-3 mb-2'><i>Atenção se você acabou de adicionar o vídeo, ele pode demorar um pouco para ser processado.</i></p>
                {
                  (plans || []).map(plan =>
                        <Badge key={`album-plan-${Math.random()}`}
                            color={ albumPlans.find( p => p.id === plan.id ) ? 'success' : 'danger' }
                            className="ml-3"
                            style={{cursor: 'pointer'}}
                            onClick={ () =>
                                dispatch(
                                    {
                                        type: 'albums/togglePlan',
                                        payload: {
                                            plan_id: plan.id,
                                            album_id: album.id,
                                            action: albumPlans.find( p => p.id === plan.id ) ? 'remove' : 'add'
                                        }
                                    }
                                )
                            }
                        >
                            {plan.name}
                        </Badge>
                    )
                }
            </CardHeader>
            <CardBody>
                <VideoCollection className="pt-4 pb-4">
                { !showForm && album.videos && album.videos.map( v =>
                    <div key={`video-${Math.random()}`}>
                        <Badge color='danger' onClick={() => deleteVideo(v.id, album.id)}>X</Badge>
                        <Video video_url={v.urls.video} thumb={v.urls.thumbnail} ></Video>
                    </div>
                )
                }
                { showForm && <VideoForm album_id={album.id}/>
                }
                </VideoCollection>
            </CardBody>
            <CardFooter className="pl-4 pb-4">
                { !showForm &&  <Button onClick={() => setShowForm(!showForm)}>Criar novo video</Button> }
                { showForm &&  <Button onClick={() => setShowForm(!showForm)}>Mostrar Videos</Button> }
            </CardFooter>
        </Card>
    );
};

export default Album;
