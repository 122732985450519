import React from 'react';
import {Button, Card, CardBody, CardImg, CardSubtitle, CardText, CardTitle, CardHeader} from "reactstrap";

const Plan = ({subscription}) => {
    return (
        <Card className="mt-2" style={{width: 300, padding: 10, margin: 20, alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex'}}>
            <CardHeader>
                <CardImg style={{ maxWidth: 100, maxHeight: 100, borderRadius: 50 }} src={subscription.influencer.image} alt="Card image cap" />
            </CardHeader>
            <CardBody>
                <CardTitle>@{subscription.influencer.instagram}</CardTitle>
                <CardSubtitle style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{subscription.plan.name}</CardSubtitle>
                <CardText style={{height: 40, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{subscription.plan.description}</CardText>
                <Button href={`/my-account/subscription/${subscription.subscription.id}`}>veja seu plano</Button>
            </CardBody>
        </Card>
    );
};

export default Plan;
