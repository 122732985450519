// import {http} from '../../lib/http';

import {http_without_auth as http, http_with_cognito} from '../../lib/http';

export const subscriber = {
  state: {
    email: null,
    instagram: null,
    influencer: {},
    plan: null,
    code: '',
    me: {},
    currentPlan: false
  }, // initial state
  reducers: {
    setSubscriber(state, payload){
      return { ...state, ...payload}
    },
    setInfluencer(state, payload){
      const influencer = payload
      return {...state, influencer}
    },
    setMe(state, payload){
      return { ...state, me: payload}
    },
    setPlan(state, payload){
      const plan = payload
      return {...state, plan}
    },
    setCode(state, payload){
      const code = payload
      return {...state, code}
    },
  },
  effects: dispatch => ({
    // handle state changes with impure functions.
    // use async/await for async actions

    async getInfluencer(payload, rootState){
      http.get(`public_profile/${payload}`)
        .then( profile => dispatch.subscriber.setInfluencer(profile.data))
        .catch( () => dispatch.subscriber.setInfluencer({}));
    },

    async getSubscriber(payload, rootState){
      http_with_cognito.get('/subscribers/subscriber').then(me => dispatch.subscriber.setMe(me.data))
    },

    async getPlan(payload, rootState){
      http_with_cognito.get(`/subscribers/plan/${payload}`).then(me => dispatch.subscriber.setMe(me.data))
    },
  }),
}
