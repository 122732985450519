import React, {useEffect} from 'react';
import { Table, Button, } from 'reactstrap'
import {useDispatch, useSelector} from "react-redux";

export default function SubscribersList() {
    const dispatch = useDispatch();

    const customers = useSelector( state => state.influencer.customers )

    useEffect(() => {
        dispatch({type: 'influencer/getCustomers'})
    }, [])

  return (
    <div>
        <h3 className="title">Inscritos:</h3>
      <Table responsive>
    <thead>
        <tr>
            <th className="text-center">#</th>
            <th>Nome</th>
            <th className="text-center">Plano</th>
            <th className="text-right">Data</th>
            <th className="text-right">Ações</th>
        </tr>
    </thead>
    <tbody>
    { customers.map((i) =>
        <tr key={`customers-${Math.random()}`}>
            <td className="text-center">{i.subscription.external_id}</td>
            <td>{i.customer.name}</td>
            <td className="text-center">{i.plan.name}</td>
            <td className="text-right">{i.customer.created_at}</td>
            <td className="text-right">
                <Button className="btn-icon" color="info" size="sm">
                    <i className="fa fa-user"></i>
                </Button>
            </td>
        </tr>)
    }
    </tbody>
</Table>
    </div>
  )
}
