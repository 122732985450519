import {http} from '../../lib/http';
import { getPersistor } from '@rematch/persist';

//toast
import { toast } from 'react-toastify';

export const influencer = {
  state: {
    current_influencer: {},
    bank_account: {},
    loading: {
      bank_account: false,
      influencer: false,
    },
    plans: [],
    transactions: [],
    balance: {
      waiting_funds: { amount: 0 },
      transferred: { amount: 0 },
      available: { amount: 0 },
    },
    instagram_accounts: [],
    customers: []
  }, // initial state
  reducers: {
    setInfluencer(state, payload){
      return { ...state, current_influencer: payload }
    },
    setBankAccount(state, payload){
      return { ...state, bank_account: payload}
    },
    setLoading(state, payload){
      return { ...state, loading: { ...state.loading, ...payload}}
    },
    setPlans(state, payload){
      return { ...state, plans: payload }
    },
    setTransactions(state, payload){
      return { ...state, transactions: payload}
    },
    setBalance(state, payload){
      return { ...state, balance: payload}
    },
    setCustomers(state, payload){
      return { ...state, customers: payload}
    },
    setInstagramAccounts(state, payload){
      return { ...state, instagram_accounts: payload}
    },
  },
  effects: dispatch => ({
    async login(payload, rootState) {
      try {
        const influencer = await http.post('influencer/sign_in', {
          "email": payload.email,
          "password": payload.password
        });

        if(!influencer.data.active){
          toast.error("Espere a nossa equipe entrar em contato com você! Seu perfil ainda não está ativo.")
        }
        else {
          toast.success("Login com Sucesso!")
          setTimeout(async () => {
            await dispatch.influencer.setInfluencer(influencer.data);
            await dispatch.influencer.getBankAccount();
          }, 1000)
        }

      } catch (error) {
        toast.warning("Erro, Login Errado.")
      }

    },
    async register(payload, rootState) {
      try {
        await http.post('influencer', payload);
        const influencer = await http.get('influencer/me');
        toast.success('Registro com Sucesso!');
        setTimeout(async () => {
          await dispatch.influencer.setInfluencer(influencer)
        }, 1000)
      } catch (error) {
        toast.warning("Erro em Registrar Usuário.")
      }

    },
    async verifyToken(payload, rootState) {
      await http.get('influencer/validate_token')
    },
    async updateProfile(payload, rootstate) {
      try {
        await http.post('influencer/update_profile', payload)
        const influencer = await http.get('influencer/me')
        toast.success('Dados alterados com sucesso!');
        dispatch.influencer.setInfluencer(influencer)
      } catch {
        toast.warning("Erro em Alterar os Dados.")
      }

    },
    async createPlan(payload, rootstate) {
      try{
        await http.post('plans/create', payload);
        toast.success("Plano criado com Sucesso!")
        await dispatch.influencer.listPlans();
      } catch {
        toast.error("Erro ao Criar Plano.")
      }

    },
    async listPlans(payload, rootstate) {
      const plans = await http.get('plans/list', payload)
      dispatch.influencer.setPlans(plans)
    },
    async updateBankAccount(payload, rootstate) {
      try{
        dispatch.influencer.setLoading({bank_account: true})
        await http.post('bank_account/update_bank_account', payload)
        toast.success('Conta Bancária Atualizada com Sucesso!')
        dispatch.influencer.getBankAccount()
        dispatch.influencer.setLoading({bank_account: false})
      } catch {
        toast.error("Error ao Atualizar Conta Bancária")
      }

    },
    async getBankAccount(payload, rootstate) {
      dispatch.influencer.setLoading({bank_account: true})
      const bankAccount = await http.get('bank_account/get_bank_account', payload)
      dispatch.influencer.setBankAccount(bankAccount)
      dispatch.influencer.setLoading({bank_account: false})
    },
    async getBalance(payload, rootstate) {
      const balance = await http.get('bank_account/balance', payload)
      balance && dispatch.influencer.setBalance(balance)
    },
    async getCustomers(payload, rootState){
      const customers = await http.get('customers/list', payload)
      dispatch.influencer.setCustomers(customers)
    },
    async getTransactions(payload, rootstate) {
      const transactions = await http.get('transactions/list', payload)

      dispatch.influencer.setTransactions(transactions);
    },
    async changePlan(payload, rootState){

    },
    async listInstagramAccounts(payload, rootState){
      const instagram_accounts = await http.get('instagram_accounts/list')
      dispatch.influencer.setInstagramAccounts(instagram_accounts);
    },
    async addInstagramAccount(payload, rootState){
      await http.post('instagram_accounts/add', payload)
      dispatch.influencer.listInstagramAccounts();
    },
    async updateInstagramAccount(payload, rootState){
      await http.post('instagram_accounts/update', payload)
      dispatch.influencer.listInstagramAccounts();
    },
    async removeInstagramAccount(payload, rootState){
      await http.delete('instagram_accounts/remove', {
        params: payload
      })
      dispatch.influencer.listInstagramAccounts();
    },
    async addPlanInstagramAccount(payload, rootState){
      await http.post('instagram_accounts/add_plan', payload)
      dispatch.influencer.listInstagramAccounts();
    },
    async removePlanInstagramAccount(payload, rootState){
      await http.delete('instagram_accounts/remove_plan', { params: payload})
      dispatch.influencer.listInstagramAccounts();
    },
    async logout(payload, rootState) {
      const persistor = getPersistor()
      await persistor.flush();
      dispatch.influencer.setInfluencer(null);
      dispatch.influencer.setBankAccount(null);
      dispatch.influencer.setPlans(null);
      dispatch.influencer.setTransactions(null);
      dispatch.influencer.setLoading({
        bank_account: false,
        influencer: false,
      })
      dispatch.auth.resetAuthToken(null);
    },
    async flushPersistor(payload, rootState){
      const persistor = getPersistor()
      await persistor.flush();
    }
  }),
}
