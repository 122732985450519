import React, { useState, useEffect } from 'react'
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// toast
import { ToastContainer } from 'react-toastify';

export default function InfluencerLoginForm() {
  const history = useHistory();

  const [emailFocus, setEmailFocus] = useState(false);
  const [passwordFocus, setPassworFocus] = useState(false);

  const [email, setEmail] = useState('eduardo@tamaki.app');
  const [password, setPassword] = useState('123123');

  const dispatch = useDispatch();

  const currentInfluencer = useSelector(state => state.influencer.current_influencer)

  useEffect(() => {
    if(currentInfluencer && currentInfluencer.id){
      history.push('/influencer/home')
    }
  })

  const login = (e) => {
    e.preventDefault();
    dispatch({ type: 'influencer/login', payload: { email, password } })
  } 

  return (
    <Card className="card-login">
      <ToastContainer 
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    <Form action="" className="form" method="">
      <CardHeader>
        <CardImg
          alt="..."
          src={require("assets/img/square-purple-1.png")}
        />
        <CardTitle className="ml-2" tag="h4">entrar</CardTitle>
      </CardHeader>
      <CardBody>
        <InputGroup
          className={classnames("input-lg", {
            "input-group-focus": emailFocus
          })}
        >
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="tim-icons icon-single-02" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="email"
            type="email"
            value={email}
            onChange={ e => setEmail(e.target.value)}
            onFocus={e => setEmailFocus(true)}
            onBlur={e => setEmailFocus(false)}
          />
        </InputGroup>
        <InputGroup
          className={classnames("input-lg", {
            "input-group-focus": passwordFocus
          })}
        >
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="tim-icons icon-caps-small" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="password"
            type="password"
            value={password}
            onChange={ e => setPassword(e.target.value)}
            onFocus={e => setPassworFocus(true)}
            onBlur={e =>setPassworFocus(false)}
          />
        </InputGroup>
      </CardBody>
      <CardFooter className="text-center">
        <Button
          block
          className="btn-round"
          color="primary"
          href="#pablo"
          onClick={login}
          size="lg"
        >
          entrar
        </Button>
      </CardFooter>
      <div className="pull-left ml-3 mb-3">
        <h6>
          <a
            className="link footer-link"
            href="/influencer-register"
          >
            Criar uma conta
          </a>
        </h6>
      </div>
      <div className="pull-right mr-3 mb-3">
        <h6>
          <a
            className="link footer-link"
            href="#close-friends"
            onClick={e => e.preventDefault()}
          >
            ajuda?
          </a>
        </h6>
      </div>
    </Form>
  </Card>
  )
}
