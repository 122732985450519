import {http_with_cognito as http} from '../../lib/http';
//toast
import { toast } from 'react-toastify';
const InitialState = {
  fullName: null,
  cpf: null,
  paymentType: null,
  creditCard: null,
  boleto: null,
  status: "ready",
  showModalCode: false,
  message: false
}

export const payment = {
  state: InitialState, // initial state
  reducers: {
    setSubscriber(state, payload){
      return { ...state, ...payload}
    },
    setStatus(state, payload){
      return { ...state, status: payload}
    },
    setShowModalCode(state, payload){
      return { ...state, showModalCode: payload, status: "error"}
    },
    setMessage(state, payload){
      return { ...state, message: payload}
    },
    clean(state, payload){
      return InitialState
    }
  },
  effects: dispatch => ({
    // handle state changes with impure functions.
    // use async/await for async actions

    async creditCardPayment(payload, rootState){
      dispatch.payment.setStatus("processing")
      http.post(`influencer/credit_card`,{
        credit_card_payment: payload
      }).then( response => {
        if (response && response.data && response.data.errors && response.data.errors.messages) {
          if(response.data.errors.messages.action == "show_code_modal"){
            dispatch.payment.setShowModalCode(true);
          } else if(response.data.errors.messages && response.data.errors.messages.show){
            toast.error("Erro ao gerar pagamento.")
            dispatch.payment.setStatus("error");
            dispatch.payment.setMessage(response.data.errors.messages.show)
          } else {
            toast.error("Erro ao gerar pagamento.")
            dispatch.payment.setStatus("error")};
        } else {
          toast.success("Pagamento gerado com Sucesso.")
          dispatch.payment.setStatus("success")};
      });
    },
    async boletoPayment(payload, rootState){
      dispatch.payment.setStatus("processing")
      http.post(`influencer/boleto`,{
        boleto_payment: payload
      }).then( response => {
        if (response && response.data && response.data.errors && response.data.errors.messages) {
          if(response.data.errors.messages.action == "show_code_modal"){
            dispatch.payment.setShowModalCode(true)
          } else if(response.data.errors.messages && response.data.errors.messages.show){
            toast.error("Erro ao gerar pagamento.")
            dispatch.payment.setStatus("error")
            dispatch.payment.setMessage(response.data.errors.messages.show)
          } else {
            toast.error("Erro ao gerar pagamento.")
            dispatch.payment.setStatus("error")
          }
        } else {
          toast.success("Pagamento gerado com Sucesso.")
          dispatch.payment.setStatus("success");
        }
      });
    },
  }),
}
