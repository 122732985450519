import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {
    Button,
    Form,
    Input,
    Container,
    Row,
    Col,
    FormGroup
} from "reactstrap";
import { ToastContainer } from 'react-toastify';
import {useDispatch, useSelector} from "react-redux";

const AlbumForm = () => {
    const { handleSubmit, register, errors, setValue, getValues, triggerValidation } = useForm();
    const dispatch = useDispatch();

    const onSubmit = values => {
        dispatch({ type: 'albums/createAlbum', payload: values })
    };

    const loading = useSelector(state => state.albums.loading)

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const values = getValues();

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <Form onSubmit={handleSubmit(onSubmit)} className="js-validate">
                <Container style={{paddingTop: 50}}>
                    <h3 className="title">Criação de plano:</h3>
                    <Row>
                        <Col md="12">
                            <label className="labels">
                                Nome do Album: <span className="text-danger">*</span>
                            </label>
                            <FormGroup className={(values.name !== (undefined || '')) ? (errors.name ? "has-danger" : "has-success") : ''}>
                                <Input
                                    className='form-control'
                                    placeholder="Nome"
                                    required
                                    value={name}
                                    name="name"
                                    disabled={loading}
                                    onChange={e => { setName( e.target.value);}}
                                    onBlur={ () => triggerValidation("name")}
                                    innerRef={register({
                                        required: 'Este campo deve ser preenchido',
                                        pattern: /.+/
                                    })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <label className="labels">
                                Descrição:<span className="text-danger">*</span>
                            </label>
                            <FormGroup  componentClass="textarea" rows={5} style={{maxHeight: 200}} className={(values.name !== undefined ) ? (errors.name ? "has-danger" : "has-success") : ''}>
                                <Input
                                    aria-label="Jack Wayley"
                                    name="description"
                                    placeholder="Este é o texto descritivo deste Álbum."
                                    required
                                    disabled={loading}
                                    type="textarea"
                                    style={{maxHeight: 200}}
                                    rows={5}
                                    value={description}
                                    onChange={e => { setDescription(e.target.value);  triggerValidation("description");}}
                                    innerRef={register({
                                        required: 'Este campo deve ser preenchido',
                                        pattern: /.+\s.+/
                                    })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button className="btn-round" style={{float:"right"}} type="submit" color="primary">
                        Criar
                    </Button>
                </Container>
            </Form>
        </>
    );
};

export default AlbumForm;
