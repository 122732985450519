import React, {useEffect, useState} from 'react';
import Stories from "./Stories";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {http_with_cognito} from "../../../lib/http";

const Story = () => {
  const dispatch = useDispatch();
  const planInitialState = false
  const [ plan, setPlan ] = useState(planInitialState)
  const subscriptions = useSelector(state => state.subscriber.me.subscriptions );
  let { subscriptionId } = useParams();

  const subscription = ( subscriptions || [] ).find( s => s.subscription.id === parseInt(subscriptionId) )

  const getPlan = async () => {
    const planData = await http_with_cognito.get(`subscribers/plan/${subscription.plan.id}`);
    setPlan(planData.data);
  }

  useEffect(() => {
    dispatch({type: 'subscriber/getSubscriber'});
  },[])


  if(subscription && plan === false ){
    getPlan()
  }
  return (
    <>
    { subscription && plan && <Stories subscription={subscription} plan={plan} /> }
    </>
  );
};

export default Story;
