/*!

=========================================================
* BLK Design System PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';

export default function ColorNavbar() {
  const influencer = useSelector(state => state.influencer);
  const dispatch = useDispatch();
  const history = useHistory();

  const [navbarColor, setNavbarColor] = useState("navbar-transparent");
  const changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 5 ||
      document.body.scrollTop > 5
    ) {
      setNavbarColor("bg-darker");
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      setNavbarColor("navbar-transparent");
    }
  };

  useEffect(() => { window.addEventListener("scroll", changeNavbarColor); }, [])
  return (
    <>
    <Navbar className={"fixed-top " + navbarColor} expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand to="/" tag={Link} id="tooltip6619950104">
          <img height={50} alt="..." src={require("assets/img/enjoy branco.png")} />
          </NavbarBrand>
          <button className="navbar-toggler" id="navigation">
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>

        <UncontrolledCollapse navbar toggler="#navigation">
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <img height={50} alt="..." src={require("assets/img/enjoy branco.png")} />
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button className="navbar-toggler" id="navigation">
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </Col>
            </Row>
          </div>
          <Nav className="ml-auto" navbar>
            {!(influencer && influencer.current_influencer && influencer.current_influencer.id) &&
            <div>
              <Button
                className="btn-simple btn-neutral"
                color="default"
                href="/influencer-login"
                size="sm"
              >
                <p>Entrar</p>
              </Button>
              <Button className="btn-simple btn-neutral"
                color="default"
                href="/influencer-register"
                size="sm"
              >
                <p>Cadastrar</p>
              </Button>
            </div>
            }
            {influencer && influencer.current_influencer && influencer.current_influencer.id &&
            <div>
              <Button
                className="btn-simple btn-neutral"
                color="default"
                onClick={() => { history.push('/influencer/home')}}
                size="sm"
              >
                <p>Home</p>
              </Button>
              <Button
                  className="btn-simple btn-neutral"
                  color="default"
                onClick={() => { dispatch({ type: 'influencer/logout', payload: { } }); history.push('/')}}
                size="sm"
              >
                <p>Sair</p>
              </Button>
            </div>
            }
          </Nav>
        </UncontrolledCollapse>
      </Container>
    </Navbar>
  </>
  )
}

