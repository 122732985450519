import React from "react";
import { useForm } from "react-hook-form";
import { Auth } from 'aws-amplify';
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardFooter
} from "reactstrap";
import { useHistory } from "react-router-dom";

export default function FanLogin() {
  const { handleSubmit, register, errors } = useForm();
  let history = useHistory();

  const SignIn = async (email, password) => {
    try {
      const user = await Auth.signIn(email, password);
      console.log(user)
      history.push('/payment')
    } catch (error) {
      console.log('error signing in', error);
    }
  }
  const onSubmit = async values => {
    SignIn(values.email, values.password)
  };
  return (
    <Row>
      <Col className="ml-auto mr-auto" md="8">
        <Card className="card-profile profile-bg pt-3">
        <Form onSubmit={handleSubmit(onSubmit)} className="js-validate">
          <CardTitle tag="h3">Entrar</CardTitle>
          <CardBody>
            <Col className="align-self-center" md={{ size: 10, offset: 1 }}>

              <Row className="pt-2">

                <Col className="align-self-center" md="3">
                  <label className="labels" htmlFor="#firstName">
                    e-mail
                            </label>
                </Col>
                <Col className="align-self-center" md="9">
                  <FormGroup>
                    <Input
                      placeholder="Qual o seu e-mail"
                      id="email"
                      name="email"
                      type="email"
                      innerRef={register({
                        required: 'Este campo deve ser preenchido'
                      })}
                    />
                    {errors.email && errors.email.message}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="pt-2">
                <Col className="align-self-center" md="3">
                  <label className="labels" htmlFor="#password">
                    Senha
                            </label>
                </Col>
                <Col className="align-self-center" md="9">
                  <FormGroup>
                    <Input
                      placeholder="Escolha uma senha"
                      id="password"
                      name="password"
                      type="password"
                      innerRef={register({
                        required: 'Este campo deve ser preenchido',
                        minLength: 6
                      })}
                    />
                    {errors.password && errors.password.message}
                    {errors.password && errors.password.type === "minLength" && "A senha deve ter 6 caracteres no mínimo."}
                  </FormGroup>
                </Col>
              </Row>

            </Col>
          </CardBody>
          <CardFooter>
            <div className="follow">
              <Button
                color="secondary"
                type='submit'
              >
                Entrar
              </Button>
            </div>
          </CardFooter>
          </Form>
        </Card>
      </Col>
    </Row>
  )
}
