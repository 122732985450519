import React, {useEffect} from 'react';
import {Button, Card, CardBody, Col, Row, Table} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";

const Transactions = () => {
    const dispatch = useDispatch();

    const transactions = useSelector(state => state.influencer.transactions );
    const available = useSelector(state => state.influencer.balance.available.amount );
    const waiting_funds = useSelector(state => state.influencer.balance.waiting_funds.amount );
    const tranferred = useSelector(state => state.influencer.balance.transferred.amount );

    useEffect(() => {
        dispatch({ type: 'influencer/getTransactions' });
        dispatch({ type: 'influencer/getBalance' })
    }, [])
    return (
        <div>
            <Row>
                <Col lg="4" md="12">
                    <Card data-background-color="green">
                        <CardBody>
                            <h6 className="category-social">
                                <i className="fa fa-fire mr-1" />
                                Disponível
                            </h6>
                            <h4>R$ {available}</h4>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="4" md="12">
                    <Card data-background-color="blue">
                        <CardBody>
                            <h6 className="category-social">
                                <i className="fa fa-fire mr-1" />
                                A Confirmar
                            </h6>
                            <h4>R$ {waiting_funds}</h4>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="4" md="12">
                    <Card data-background-color="red">
                        <CardBody>
                            <h6 className="category-social">
                                <i className="fa fa-fire mr-1" />
                                Transferido
                            </h6>
                            <h4>R$ {tranferred}</h4>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <hr/>
            <h4>Transações:</h4>
            <div>
                <Table responsive>
                    <thead>
                    <tr>
                        <th className="text-center">#</th>
                        <th>Fingerprint</th>
                        <th className="text-right">Data</th>
                        <th className="text-right">Status</th>
                        <th className="text-right">Ações</th>
                    </tr>
                    </thead>
                    <tbody>
                    { (transactions || []).map((t) =>
                        <tr>
                            <td className="text-center">{t.external_id}</td>
                            <td>{t.fingerprint}</td>
                            <td className="text-center">R$ {t.amount}</td>
                            <td className="text-right">{t.created_at}</td>
                            <td className="text-right">{t.current_status}</td>
                            <td className="text-right">
                                <Button className="btn-icon" color="info" size="sm">
                                    <i className="fa fa-user"></i>
                                </Button>
                            </td>
                        </tr>)
                    }
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default Transactions;
