/*!

=========================================================
* BLK Design System PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// styles
import "assets/css/style.css";
import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-pro-react.scss?v1.0.0";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
// styles for toast
import 'react-toastify/dist/ReactToastify.css';

// presentation pages
// import Index from "views/Index.jsx";
// import Presentation from "views/Presentation.jsx";
// import Sections from "views/Sections.jsx";
// // example pages
// import AboutUs from "views/examples/AboutUs.jsx";
// import BlogPost from "views/examples/BlogPost.jsx";
// import BlogPosts from "views/examples/BlogPosts.jsx";
// import ContactUs from "views/examples/ContactUs.jsx";
// import LandingPage from "views/examples/LandingPage.jsx";
// import Pricing from "views/examples/Pricing.jsx";
// import Ecommerce from "views/examples/Ecommerce.jsx";
// import ProductPage from "views/examples/ProductPage.jsx";
// import ProfilePage from "views/examples/ProfilePage.jsx";
// import Error404 from "views/examples/Error404.jsx";
// import Error500 from "views/examples/Error500.jsx";
// import AccountSettings from "views/examples/AccountSettings.jsx";
import PublicProfile from "views/CloseFriends/PublicProfile.jsx";
import LandingPage from "views/CloseFriends/LandingPage.jsx";
import InfluencerRegister from "views/CloseFriends/influencer/InfluencerRegister.jsx";
import InfluencerLoginPage from "views/CloseFriends/influencer/InfluencerLoginPage.jsx";
import InfluencerHome from "views/CloseFriends/influencer/InfluencerHome.jsx";
import Payment from "views/CloseFriends/Payment.jsx";
import MyAccount from "./views/CloseFriends/subscriber/MyAccount";
import { ConnectedRouter } from 'connected-react-router'
// import LoginPage from "views/examples/LoginPage.jsx";
// import RegisterPage from "views/examples/RegisterPage.jsx";
// import ResetPage from "views/examples/ResetPage.jsx";
// import InvoicePage from "views/examples/InvoicePage.jsx";
// import CheckoutPage from "views/examples/CheckoutPage.jsx";
// import ChatPage from "views/examples/ChatPage.jsx";
import { Provider } from 'react-redux'
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import store from './rematch'
import PlanDetailed from "./views/CloseFriends/subscriber/PlanDetailed";
import Story from "./views/CloseFriends/subscriber/Story";
import VideoSubscriber from "./views/CloseFriends/subscriber/VideoSubscriber";

Amplify.configure(awsconfig);

const isSubdomain = window.location.host.match(/([a-zA-z0-9\-]+)(\.staging)?\.enjoy\..*/);

if(isSubdomain && isSubdomain[1] && isSubdomain[1].length > 1 &&  isSubdomain[1] !== "staging"){
  ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter  >
        <Switch>
          <Route path="/payment" component={() => <Payment influencer_name={isSubdomain[1]} />} />
          <Route path="/my-account/subscription/:subscriptionId/story" component={() => <Story influencer_name={isSubdomain[1]} />} />
          <Route path="/my-account/subscription/:subscriptionId/:albumId/:videoId" component={() => <VideoSubscriber influencer_name={isSubdomain[1]} />} />
          <Route path="/my-account/subscription/:subscriptionId" component={PlanDetailed} />
          <Route path="/my-account" component={() => <MyAccount influencer_name={isSubdomain[1]} />} />
          <Route path="/" component={() => <PublicProfile influencer_name={isSubdomain[1]} />} />
        </Switch>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  );

} else {
  ReactDOM.render(
    <Provider store={store}>
      {/* <ConnectedRouter history={history} > */}
        <BrowserRouter  >
        <Switch>
          <Route path="/influencer-register" component={InfluencerRegister} />
          <Route path="/influencer-login" component={InfluencerLoginPage} />
          <Route path="/influencer/:tab" component={InfluencerHome} />
          <Route path="/" component={LandingPage} />
        </Switch>
        </BrowserRouter>
      {/* </ConnectedRouter> */}
    </Provider>,
    document.getElementById("root")
  );

}
