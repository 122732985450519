import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import Video from './albums/Video';
import {http_with_cognito} from "../../../lib/http";

const VideoSubscriber = () => {
  let video = false;
  const dispatch = useDispatch();
  const planInitialState = false
  const [ plan, setPlan ] = useState(planInitialState)
  const subscriptions = useSelector(state => state.subscriber.me.subscriptions );
  let { subscriptionId, albumId, videoId } = useParams();

  const subscription = ( subscriptions || [] ).find( s => s.subscription.id === parseInt(subscriptionId) )

  const getPlan = async () => {
    const planData = await http_with_cognito.get(`subscribers/plan/${subscription.plan.id}`);
    setPlan(planData.data);
  }

  if(subscription && plan === false ){
    getPlan()
  }

  console.log(albumId, videoId, plan)
  if(plan){
   plan.albums.map(a => {
     if(a.id == albumId) {
       a.videos.map(v => {
         if(v.id = videoId) video = v;
       })
     }
   })
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      { video && <Video
        video_url={video.urls.video}
        thumb={video.urls.thumbnail}
        height={480}
        width={640}
      /> }
    </div>
  );
};

export default VideoSubscriber;
