import React, {Fragment, useState} from 'react';
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import Button from "reactstrap/es/Button";
import PlanForm from "../plans/PlanForm";
import Plans from "../plans/Plans";

const InfluencerPlans = () => {
    const [tab, setTab] = useState('plans')
    return (
        <Fragment>
            <Row>
                <Col>
                    <Button
                        color="info"
                        className="animation-on-hover"
                        style={{width: '100%'}}
                        disabled={tab==='plans'}
                        onClick={() => setTab('plans')}
                    >Planos</Button>
                </Col>
                <Col>
                    <Button
                        color="info"
                        className="animation-on-hover"
                        style={{width: '100%'}}
                        disabled={tab==='createPlan'}
                        onClick={() => setTab('createPlan')}
                    >Criação de plano</Button>
                </Col>
            </Row>
            { tab === 'plans' && <Plans />}
            { tab === 'createPlan' && <PlanForm/>}
        </Fragment>
    );
};

export default InfluencerPlans;
