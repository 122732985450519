import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from 'react-redux'
import InputMask from 'react-input-mask';
import {
    Button,
    Form,
    Input,
    Container,
    Row,
    Col,
    FormGroup
} from "reactstrap";

// toast
import { ToastContainer } from 'react-toastify';

const PlanForm = () => {
    const { handleSubmit, register, errors, setValue, getValues, triggerValidation } = useForm();
    const dispatch = useDispatch();

    const [amount, setAmount] = useState("35,00");
    const [days, setDays] = useState(30);
    const [name, setName] = useState();
    const [trialDays, setTrialDays] = useState(0);

    const onSubmit = values => {
        dispatch({ type: 'influencer/createPlan', payload: values })
    };

    const values = getValues();

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <Form onSubmit={handleSubmit(onSubmit)} className="js-validate">
                <Container style={{paddingTop: 50}}>
                    <h3 className="title">Criação de plano:</h3>
                    <Row>
                        <Col md="6">
                            <label className="labels">
                                Nome do Plano: <span className="text-danger">*</span>
                            </label>
                            <FormGroup className={(values.name !== (undefined || '')) ? (errors.name ? "has-danger" : "has-success") : ''}>
                                <Input
                                    className='form-control'
                                    placeholder="Nome"
                                    required
                                    value={name}
                                    name="name"
                                    onChange={e => { setName( e.target.value);}}
                                    onBlur={ () => triggerValidation("name")}
                                    innerRef={register({
                                        required: 'Este campo deve ser preenchido',
                                        pattern: /.+/
                                    })}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <label className="labels">
                                Valor do plano: <span className="text-danger">*</span>
                            </label>
                            <FormGroup className={(values.amount !== (undefined || '')) ? (errors.amount ? "has-danger" : "has-success") : ''}>
                                <Input
                                    className='form-control'
                                    placeholder="Valor do plano R$35,00"
                                    required
                                    value={amount}
                                    name="amount"
                                    onChange={e => { setAmount( e.target.value);}}
                                    onBlur={ () => triggerValidation("amount")}
                                    innerRef={register({
                                        required: 'Este campo deve ser preenchido',
                                        pattern: /^[0-9]+,[0-9]{2}$/
                                    })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <label className="labels">
                                Dias de vigência do Plano: <span className="text-danger">*</span>
                            </label>
                            <FormGroup className={(values.days !== (undefined || '')) ? (errors.days ? "has-danger" : "has-success") : ''}>
                                <Input
                                    className='form-control'
                                    placeholder="Dias do plano"
                                    required
                                    value={days}
                                    name="days"
                                    onChange={e => { setDays( e.target.value);}}
                                    onBlur={ () => triggerValidation("days")}
                                    innerRef={register({
                                        required: 'Este campo deve ser preenchido',
                                        pattern: /[0-9]+/
                                    })}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <label className="labels">
                                Dias de Trial: <span className="text-danger">*</span>
                            </label>
                            <FormGroup className={(values.trial_days !== (undefined || '')) ? (errors.trial_days ? "has-danger" : "has-success") : ''}>
                                <Input
                                    className='form-control'
                                    placeholder="Dias de Trial"
                                    required
                                    value={trialDays}
                                    name="trial_days"
                                    onChange={e => { setTrialDays( e.target.value);}}
                                    onBlur={ () => triggerValidation("trial_days")}
                                    innerRef={register({
                                        required: 'Este campo deve ser preenchido',
                                        pattern: /^[0-9]+$/
                                    })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button className="btn-round" style={{float:"right"}} type="submit" color="primary">
                        Criar
                    </Button>
                </Container>
            </Form>
        </>
    );
};

export default PlanForm;
