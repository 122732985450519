import React, {useEffect, useState} from 'react';
import { useForm, Controller } from "react-hook-form";
import {useDispatch, useSelector} from 'react-redux'
import InputMask from 'react-input-mask';
import {
  Button,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup
} from "reactstrap";
import { useHistory } from "react-router-dom";

// toast
import { ToastContainer } from 'react-toastify';

export default function BankAccount() {
  const { handleSubmit, register, errors, setValue, getValues, triggerValidation } = useForm();
  const dispatch = useDispatch();

  const bankAccount = useSelector(state => state.influencer.bank_account);

  const [legalName, setLegalName] = useState((bankAccount && bankAccount.legal_name) || '');
  const [documentNumber, setDocumentNumber] = useState((bankAccount && bankAccount.document_number) || '');
  const [bankCode, setBankCode] = useState((bankAccount && bankAccount.bank_code) || '');
  const [agencia, setAgencia] = useState((bankAccount && bankAccount.agencia) || '');
  const [agenciaDv, setAgenciaDv] = useState((bankAccount && bankAccount.agencia_dv) || '');
  const [accountType, setAccountType] = useState((bankAccount && bankAccount.account_type) || '');
  const [conta, setConta] = useState((bankAccount && bankAccount.conta) || '');
  const [contaDv, setContaDv] = useState((bankAccount && bankAccount.conta_dv) || '');

  const getBankAccount = () => dispatch({ type: 'influencer/getBankAccount' })

  useEffect(() => {
    getBankAccount();
  }, [])

  useEffect(() => {
    setValue('legal_name', legalName);
    setValue('document_number', documentNumber);
    setValue('bank_code', bankCode);
    setValue('agencia', agencia);
    setValue('agencia_dv', agenciaDv);
    setValue('account_type', accountType);
    setValue('conta', conta);
    setValue('conta_dv', contaDv);
  }, [legalName, documentNumber, bankCode, agencia, agenciaDv, accountType,contaDv,conta])

  const onSubmit = values => {
    dispatch({ type: 'influencer/updateBankAccount', payload: values })
  };

  const values = getValues();

  register({ name: 'document_number' }, { required: 'Este campo deve ser preenchido', pattern: /[0-9\.-]{13}/ });
  return (
      <>
      <ToastContainer 
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    <Form onSubmit={handleSubmit(onSubmit)} className="js-validate">
      <Container style={{paddingTop: 50}}>
        <h3 className="title">Sua conta do banco:</h3>
        <Row>
          <Col md="6">
            <label className="labels">
              Nome Completo: <span className="text-danger">*</span>
            </label>
            <FormGroup className={(values.legal_name !== (undefined || '')) ? (errors.legal_name ? "has-danger" : "has-success") : ''}>
              <Input
                  className='form-control'
                  placeholder="Nome Legal"
                  required
                  value={legalName}
                  name="legal_name"
                  onChange={e => { setLegalName( e.target.value);}}
                  onBlur={ () => triggerValidation("legal_name")}
                  innerRef={register({
                    required: 'Este campo deve ser preenchido',
                    pattern: /.+\s.+/
                  })}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <label className="labels">
              CPF <span className="text-danger">*</span>
            </label>
            <FormGroup className={(values.document_number !== (undefined || '')) ? (errors.cpf ? "has-danger" : "has-success") : ''}>
              <InputMask
                  className='form-control'
                  mask="999.999.999-99"
                  placeholder="999.999.999-99"
                  type="tel"
                  name="document_number"
                  value={documentNumber}
                  onChange={e => { setDocumentNumber(e.target.value)}}
                  onBlur={ triggerValidation("document_number")}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <label className="labels">
              Código do Banco <span className="text-danger">*</span>
            </label>
            <FormGroup className={(values.bank_code !== undefined ) ? (errors.bank_code ? "has-danger" : "has-success") : ''}>
                <Input
                  name="bank_code"
                  placeholder="Código do Banco"
                  required=""
                  type="text"
                  mask="999"
                  value={bankCode}
                  onChange={e => { setBankCode(e.target.value);}}
                  onBlur={() => triggerValidation("bank_code")}
                  innerRef={register({
                    required: 'Este campo deve ser preenchido',
                    pattern: {
                      value: /^[0-9]{3}$/
                    }
                  })}
                />
              </FormGroup>
          </Col>
          <Col md="3">
            <label className="labels">
              Agência <span className="text-danger">*</span>
            </label>
            <FormGroup className={(values.agencia !== undefined ) ? (errors.agencia ? "has-danger" : "has-success") : ''}>
              <Input
                  name="agencia"
                  placeholder="Código do Banco"
                  required=""
                  type="text"
                  mask="99999"
                  value={agencia}
                  onChange={e => { setAgencia(e.target.value);}}
                  onBlur={() => triggerValidation("agencia")}
                  innerRef={register({
                    required: 'Este campo deve ser preenchido',
                    pattern: {
                      value: /^[0-9]{5}$/
                    }
                  })}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <label className="labels">
              Digito Agência <span className="text-danger">*</span>
            </label>
            <FormGroup className={(values.agencia_dv !== undefined ) ? (errors.agencia_dv ? "has-danger" : "has-success") : ''}>
              <Input
                  name="agencia_dv"
                  placeholder="Código do Banco"
                  required=""
                  type="text"
                  mask="9"
                  value={agenciaDv}
                  onChange={e => { setAgenciaDv(e.target.value);}}
                  onBlur={() => triggerValidation("agencia_dv")}
                  innerRef={register({
                    required: 'Este campo deve ser preenchido',
                    pattern: {
                      value: /^[0-9]{1}$/
                    }
                  })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <label className="labels">
              Tipo de Conta <span className="text-danger">*</span>
            </label>
            <FormGroup className={(values.account_type !== undefined ) ? (errors.account_type ? "has-danger" : "has-success") : ''}>
              <Input
                  type="select"
                  name="account_type"
                  id="exampleSelect"
                  value={accountType}
                  onChange={e => { setAccountType(e.target.value) }}
                  innerRef={register({
                    required: 'Este campo deve ser preenchido'})}
              >
                <option value={"conta_corrente"}>Conta Corrente</option>
                <option value={"conta_poupanca"}>Conta Poupança</option>
                <option value={"conta_corrente_conjunta"}>Conta Corrente Conjunta</option>
                <option value={"conta_poupanca_conjunta"}>Conta Poupança Consjunta</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md="3">
            <label className="labels">
              Conta <span className="text-danger">*</span>
            </label>
            <FormGroup className={(values.conta !== undefined ) ? (errors.conta ? "has-danger" : "has-success") : ''}>
              <Input
                  name="conta"
                  placeholder="Código do Banco"
                  required=""
                  type="text"
                  mask="99999"
                  value={conta}
                  onChange={e => { setConta(e.target.value);}}
                  onBlur={() => triggerValidation("conta")}
                  innerRef={register({
                    required: 'Este campo deve ser preenchido',
                    pattern: {
                      value: /^[0-9]{5}$/
                    }
                  })}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <label className="labels">
              Digito Conta <span className="text-danger">*</span>
            </label>
            <FormGroup className={(values.conta_dv !== undefined ) ? (errors.conta_dv ? "has-danger" : "has-success") : "has-danger" }>
              <Input
                  name="conta_dv"
                  placeholder="Código do Banco"
                  required=""
                  type="text"
                  mask="9"
                  value={contaDv}
                  onChange={e => { setContaDv(e.target.value);}}
                  onBlur={() => triggerValidation("conta_dv")}
                  innerRef={register({
                    required: 'Este campo deve ser preenchido',
                    pattern: {
                      value: /^[0-9]{1}$/
                    }
                  })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Button className="btn-round" style={{float:"right"}} type="submit" color="primary">
          Salvar
        </Button>
      </Container>
    </Form>
      </>
  )
}
