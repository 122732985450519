import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Auth } from 'aws-amplify';
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter
} from "reactstrap";
import { useDispatch, useSelector } from 'react-redux'
import SearchProfile from './components/SearchProfile';
// core components
import InfluencerNavbar from "components/Navbars/SubscriberNavbar.jsx";
import { useHistory } from "react-router-dom";
import FanLogin from "./FanLogin";


let PublicProfile = ({ influencer_name }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { handleSubmit, register, errors, setValue, getValues } = useForm();
  const influencer_param = influencer_name;
  const influencer = useSelector(state => state.subscriber.influencer)

  const SignIn = async (email, password) => {
    try {
      const user = await Auth.signIn(email, password);
      history.push('/payment')
    } catch (error) {
      console.log('error signing in', error);
    }
  }

  const onSubmit = async values => {
    try {
      const user = await Auth.signUp({
        username: values.email,
        password: values.password,
        attributes: {
          email: values.email,
          nickname: values.instagram.username
        }
      });
      SignIn(values.email, values.password)
    } catch (error) {
      // console.log('error signing up:', error);
    }


    //dispatch({ type: 'subscriber/setSubscriber', payload: values })
    //history.push('/payment')
  };

  useEffect(() => {
    dispatch({ type: 'subscriber/getInfluencer', payload: influencer_param })
    const fetchUserSession = async () => {
      try {
        Auth.currentAuthenticatedUser().then(user => {
          history.push('/payment');
        });
      } catch (error) {
        console.log(error)
      }
    }
    fetchUserSession()
  }, []);

  register({ name: 'instagram' }, { required: 'Este campo deve ser preenchido' });

  const values = getValues();
  console.log(errors)
  return (
    <>
      <InfluencerNavbar />
      <div className="wrapper">
        <div className="section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <Card className="card-profile profile-bg">
                  <Form onSubmit={handleSubmit(onSubmit)} className="js-validate">
                    <CardHeader>
                      <div className="card-avatar">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img img-raised"
                            src={influencer.image}
                          />
                        </a>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Col className="align-self-center" md={{ size: 10, offset: 1 }}>
                        <CardTitle tag="h3">@{influencer.instagram}</CardTitle>
                        <h6 className="category text-primary">Close Friends</h6>
                        <p className="card-description">
                          {influencer.description}
                        </p>

                        <Row className="pt-5">
                          <Col className="align-self-center" md="3">
                            <label className="labels" htmlFor="#firstName">
                              Instagram
                            </label>
                          </Col>
                          <Col className="align-self-center" md="9">
                            <FormGroup>
                              <SearchProfile valueChange={instagram => setValue('instagram', instagram[0])} />
                              {errors.instagram && errors.instagram.message}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="pt-2">
                          <Col className="align-self-center" md="3">
                            <label className="labels" htmlFor="#firstName">
                              e-mail
                            </label>
                          </Col>
                          <Col className="align-self-center" md="9">
                            <FormGroup>
                              <Input
                                placeholder="Qual o seu e-mail"
                                id="email"
                                name="email"
                                type="email"
                                innerRef={register({
                                  required: 'Este campo deve ser preenchido'
                                })}
                              />
                              {errors.email && errors.email.message}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="pt-2">
                          <Col className="align-self-center" md="3">
                            <label className="labels" htmlFor="#password">
                              Senha
                            </label>
                          </Col>
                          <Col className="align-self-center" md="9">
                            <FormGroup>
                              <Input
                                placeholder="Escolha uma senha"
                                id="password"
                                name="password"
                                type="password"
                                innerRef={register({
                                  required: 'Este campo deve ser preenchido',
                                  minLength: 6
                                })}
                              />
                              {errors.password && errors.password.message}
                              {errors.password && errors.password.type === "minLength" && "A senha deve ter 6 caracteres no mínimo."}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="pt-2">
                          <Col className="align-self-center" md="3">
                            <label className="labels" htmlFor="#passwordConfirmation">
                              Confirmação de Senha
                            </label>
                          </Col>
                          <Col className="align-self-center" md="9">
                            <FormGroup>
                              <Input
                                placeholder="Escolha uma senha"
                                id="passwordConfirmation"
                                name="passwordConfirmation"
                                type="password"
                                innerRef={register({
                                  required: 'Este campo deve ser preenchido',
                                  validate: value => value === values.password
                                })}
                              />
                              {errors.passwordConfirmation && errors.passwordConfirmation.message}
                              {errors.passwordConfirmation && errors.passwordConfirmation.type === "validate" && "Deve ser igual a senha"}
                            </FormGroup>
                          </Col>
                        </Row>

                      </Col>

                    </CardBody>
                    <CardFooter>
                      <div className="follow">
                        <Button
                          color="primary"
                          type='submit'
                        >
                          Cadastrar
                        </Button>
                      </div>
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            </Row>
            <FanLogin />
          </Container>
        </div>
      </div>
    </>
  )
}

export default PublicProfile;
