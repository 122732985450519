import React, { useEffect, useState } from "react";
import classnames from "classnames";
import {
  NavItem,
  NavLink,
  Nav,
  Badge,
  TabContent,
  TabPane,
  Container,
  Row,
  Col
} from "reactstrap";
import { useDispatch, useSelector } from 'react-redux'
import ColorNavbar from "components/Navbars/ColorNavbar.jsx";
import InfluencerForm from "./configuration/InfluencerUpdateForm";
import SubscribersList from "./subscribers/SubscribersList";
import { useHistory } from 'react-router-dom';
import InfluencerFinance from "./configuration/InfluencerFinance";
import InfluencerDashboard from "./configuration/InfluencerDashboard";
import InfluencerPlans from "./configuration/InfluencerPlans";
import InfluencerAlbums from './configuration/InfluencerAlbums'
import InfluencerCloseFriends from "./configuration/InfluencerCloseFriends";

export default function InfluencerHome(props) {
  const [profileTabs, setProfileTabs] = useState(props.match.params.tab)
  const currentInfluencer = useSelector(state => state.influencer.current_influencer)
  const rehydrated = useSelector(state => state._persist.rehydrated)
  const history = useHistory();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    document.body.classList.add("account-settings");
  }, [])

  useEffect(() => {
    if(!(currentInfluencer && currentInfluencer.id) && rehydrated){
      history.push('/')
    }
  })

  useEffect(( ) => {
    history.push(profileTabs)
  },[profileTabs])

  return (
    <>
    <ColorNavbar />
    <div className="wrapper">
      <div className="section">
        <Container>
          <Row>
            <Col md="3">
              <div className="section">
                {/* User Information */}
                <section className="text-center">
                  <div className="card-avatar">
                    <a href="#" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={currentInfluencer && currentInfluencer.image}
                      />
                    </a>
                  </div>
                  <h3 className="title">{ currentInfluencer && currentInfluencer.name }</h3>
                </section>
                <section>
                  <br />
                  <Nav className="flex-column nav-tabs-info" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: profileTabs === "home"
                        })}
                        onClick={e => setProfileTabs("home")}
                        href="#"
                      >
                        <i className="tim-icons icon-bulb-63" /> Dashboard
                      </NavLink>
                    </NavItem>
                    <hr className="line-info" />
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: profileTabs === "update-profile"
                        })}
                        onClick={e => setProfileTabs("update-profile")}
                        href="#"
                      >
                        <i className="tim-icons icon-single-02" /> Altere Seus dados
                      </NavLink>
                    </NavItem>
                    <hr className="line-info" />
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: profileTabs === 'finance'
                        })}
                        onClick={e => setProfileTabs('finance')}
                        href="#"
                      >
                        <i className="tim-icons icon-credit-card" /> Financeiro <Badge color="danger" className="ml-1">1</Badge>
                      </NavLink>
                    </NavItem>
                    <hr className="line-info" />
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: profileTabs === 'subscribers'
                        })}
                        onClick={e => setProfileTabs('subscribers')}
                        href="#"
                      >
                        <i className="tim-icons icon-badge" />{" "}
                        Inscritos <Badge color="success" className="ml-1">9874</Badge>
                      </NavLink>
                    </NavItem>
                    <hr className="line-info" />
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: profileTabs === 'plans'
                        })}
                        onClick={e => setProfileTabs('plans')}
                        href="#"
                      >
                        <i className="tim-icons icon-shape-star" />{" "}
                        Planos <Badge color="default" className="ml-1">15</Badge>
                      </NavLink>
                    </NavItem>
                    <hr className="line-info" />
                    <NavItem>
                      <NavLink
                          className={classnames({
                            active: profileTabs === 'albums'
                          })}
                          onClick={e => setProfileTabs('albums')}
                          href="#"
                      >
                        <i className="tim-icons icon-video-66" />{" "}
                        Albums <Badge color="default" className="ml-1">15</Badge>
                      </NavLink>
                    </NavItem>
                    <hr className="line-info" />
                    <NavItem>
                      <NavLink
                          className={classnames({
                            active: profileTabs === 'close-friends'
                          })}
                          onClick={e => setProfileTabs('close-friends')}
                          href="#"
                      >
                        <i className="tim-icons icon-heart-2" />{" "}
                        Close Friends
                      </NavLink>
                    </NavItem>
                  </Nav>
                </section>
              </div>
            </Col>
            <Col className="ml-auto" md="8">
              <div className="section">
                <TabContent activeTab={"profile-" + profileTabs}>
                <TabPane tabId="profile-home">
                  { profileTabs ===  'home' && <InfluencerDashboard /> }
                  </TabPane>
                  <TabPane tabId="profile-update-profile">
                  { currentInfluencer && currentInfluencer.id && profileTabs ===  'update-profile' &&  <InfluencerForm influencer={currentInfluencer} /> }
                  </TabPane>
                  <TabPane tabId="profile-finance">
                    { profileTabs ===  'finance' && <InfluencerFinance />}
                  </TabPane>
                  <TabPane tabId="profile-subscribers">
                    { profileTabs ===  'subscribers' && <SubscribersList /> }
                  </TabPane>
                  <TabPane tabId="profile-plans">
                    { profileTabs ===  'plans' && <InfluencerPlans />}
                  </TabPane>
                  <TabPane tabId="profile-albums">
                    { profileTabs ===  'albums' && <InfluencerAlbums />}
                  </TabPane>
                  <TabPane tabId="profile-close-friends">
                    { profileTabs ===  'close-friends' && <InfluencerCloseFriends />}
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </>
  )
}
