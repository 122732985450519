import React from 'react'
import {
  Container,
  Row,
  Col
} from "reactstrap";
import CardDashboard from './CardDashboard'

export default function InfluencerDashboard() {
  return (
            <Container>
              <div className="title">
                <h3 className="title">Dashboard</h3>
              </div>
              <Row>
                <Col lg="6" md="6">
                  <CardDashboard
                    title="Financeiro"
                    subtitle="Adicione a sua conta"
                    content="Adicione a sua conta corrente para que possamos, fazer a divisão de comissões de forma adequada."
                    buttonTitle="Conta Corrente"
                    icon="tim-icons icon-money-coins mr-1"
                    link="/influencer/finance"
                  />
                  <CardDashboard
                    title="Financeiro"
                    subtitle="Adicione a sua conta"
                    content="Adicione a sua conta corrente para que possamos, fazer a divisão de comissões de forma adequada."
                    buttonTitle="Conta Corrente"
                    icon="tim-icons icon-money-coins mr-1"
                    link="/influencer/finance"
                  />
                </Col>
                <Col lg="6" md="6">
                  <CardDashboard
                    title="Financeiro"
                    subtitle="Adicione a sua conta"
                    content="Adicione a sua conta corrente para que possamos, fazer a divisão de comissões de forma adequada."
                    buttonTitle="Conta Corrente"
                    icon="tim-icons icon-money-coins mr-1"
                    link="/influencer/finance"
                  />
                  <CardDashboard
                    title="Financeiro"
                    subtitle="Adicione a sua conta"
                    content="Adicione a sua conta corrente para que possamos, fazer a divisão de comissões de forma adequada."
                    buttonTitle="Conta Corrente"
                    icon="tim-icons icon-money-coins mr-1"
                    link="/influencer/finance"
                  />
                </Col>
              </Row>
            </Container>
  )
}
