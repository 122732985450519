import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Container,
  Col
} from "reactstrap";
import InfluencerLoginForm from './InfluencerLoginForm';
// core components
import ColorNavbar from "components/Navbars/ColorNavbar.jsx";

class InfluencerLoginPage extends React.Component {
  componentDidMount() { 
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    document.body.classList.add("login-page");
  }
  render() {
    return (
      <>
        <ColorNavbar />
        <div className="page-header">
          <div className="squares square1" />
          <div className="squares square2" />
          <div className="squares square3" />
          <div className="squares square4" />
          <div className="squares square5" />
          <div className="squares square6" />
          <div className="page-header-image" />
          <Container>
            <Col className="mx-auto" lg="5" md="8">
              <InfluencerLoginForm />
            </Col>
          </Container>
        </div>
      </>
    );
  }
}

export default InfluencerLoginPage;
