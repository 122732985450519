import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Table} from "reactstrap";

const Plans = () => {
    const plans = useSelector( state => state.influencer.plans );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch.influencer.listPlans();
    }, [])

    return (
        <div>
            <h3 className="title">Planos:</h3>
            <Table responsive>
                <thead>
                <tr>
                    <th className="text-center">#</th>
                    <th>Nome</th>
                    <th className="text-center">Dias</th>
                    <th className="text-right">Dias de Trial</th>
                    <th className="text-right">Valor</th>
                </tr>
                </thead>
                <tbody>
                { (plans || []).map((i) =>
                    <tr key={`plans-${Math.random()}`}>
                        <td className="text-center">{i.external_id}</td>
                        <td>{i.name}</td>
                        <td className="text-center">{i.days}</td>
                        <td className="text-right">{i.trial_days}</td>
                        <td className="text-right">
                            R$ {i.amount}
                        </td>
                    </tr>)
                }
                </tbody>
            </Table>
        </div>
    )
};

export default Plans;
