import React, {Fragment, useState} from 'react';
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import Button from "reactstrap/es/Button";
import Albums from "../albums/Albums";
import AlbumForm from "../albums/AlbumForm";

const InfluencerAlbums = () => {
    const [tab, setTab] = useState('albums')
    return (
        <div>
            <Row>
                <Col>
                    <Button
                        color="info"
                        className="animation-on-hover"
                        style={{width: '100%'}}
                        disabled={tab==='albums'}
                        onClick={() => setTab('albums')}
                    >Album</Button>
                </Col>
                <Col>
                    <Button
                        color="info"
                        className="animation-on-hover"
                        style={{width: '100%'}}
                        disabled={tab==='createAlbum'}
                        onClick={() => setTab('createAlbum')}
                    >Criar Álbum</Button>
                </Col>
            </Row>
            { tab === 'albums' && <Albums />}
            { tab === 'createAlbum' && <AlbumForm />}
        </div>
    );
};

export default InfluencerAlbums;
