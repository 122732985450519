import 'react-credit-cards/es/styles-compiled.css'
import React, { useState, useEffect } from 'react';
import Cards from 'react-credit-cards';
import InputMask from 'react-input-mask';
import {
  FormGroup,
  Button,
  Input,
  Row,
  Col,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
// toast
import { ToastContainer } from 'react-toastify'; 

let CreditCardForm = () => {
  const { register, watch, errors, setValue, getValues, handleSubmit, triggerValidation } = useForm();
  const [modalCode, setModalCode] = useState(true)
  const [cardFocus, setCardFocus] = useState('number');
  const dispatch = useDispatch();

  const subscriber = useSelector(state => state.subscriber);
  const showModalCode = useSelector(state => state.payment.showModalCode);
  const onSubmit = async (values) => {
    await dispatch({ type: 'payment/creditCardPayment', payload: {creditCard: values, subscriber} });
  }

  const setCodeSubscriber = async (code) => {
    await dispatch({ type: 'subscriber/setCode', payload: code });
  }

  const toggleModalCode = () => {
    setModalCode(!modalCode)
  }

  useEffect(() => {
    dispatch({ type: 'subscriber/setCode', payload: "" });
  }, [])

  register({ name: 'number' }, { required: 'Este campo deve ser preenchido', pattern: /[0-9\s]{19}/ })
  register({ name: 'cvc' }, { required: 'Este campo deve ser preenchido', pattern: /[0-9]{3}/ })
  register({ name: 'expiry' }, { required: 'Este campo deve ser preenchido', pattern: /[0-9]{2}\/[0-9]{2}/ })
  register({ name: 'cpf' }, { required: 'Este campo deve ser preenchido', pattern: /[0-9.-]{13}/ })
  register({ name: 'cellphone' }, { required: 'Este campo deve ser preenchido', pattern: /[0-9 ()-]{15}/ })
  const values = getValues();

  return (
    <div id="PaymentForm" className="pt-5">

      <ToastContainer 
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Cards
          cvc={watch('cvc') || ''}
          expiry={watch('expiry') || ''}
          focused={cardFocus}
          name={watch('cardName') || ''}
          number={watch('number') || ''}
          placeholders={{ expiry: 'validade', name: 'Seu Nome' }}
        />
        <p className="pt-3">Seus dados de pagamento: </p>
        <Row>
          <Col md="12" className="pt-3">
            <div className="js-form-message">
              <label className="form-label">
                Número do Cartão *
                            </label>
              <FormGroup className={(values.number !== (undefined || '')) ? (errors.number ? "has-danger" : "has-success") : ''}>
                <InputMask
                  className='form-control'
                  mask="9999 9999 9999 9999"
                  placeholder="**** **** **** ****"
                  type="tel"
                  name="number"
                  onChange={e => { setValue('number', e.target.value);  triggerValidation("number");}}
                  onFocus={() => setCardFocus('number')}
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="6" className="pt-3">
            <div className="js-form-message mb-6">
              <label className="form-label">
                Nome (Como escrito no cartão) *
                            </label>
              <FormGroup className={(values.cardName !== undefined ) ? (errors.cardName ? "has-danger" : "has-success") : ''}>
                <Input
                  aria-label="Jack Wayley"
                  name="cardName"
                  placeholder="Seu Nome"
                  required=""
                  type="text"
                  onChange={e => { setValue('cardName', e.target.value);  triggerValidation("cardName");}}
                  innerRef={register({
                    required: 'Este campo deve ser preenchido',
                    pattern: /.+\s.+/
                  })}
                  onFocus={() => setCardFocus('name')}
                />
              </FormGroup>
            </div>
          </Col>
          <Col md="3" className="pt-3">
            <div className="js-form-message mb-6">
              <label className="form-label">Validade *</label>
              <FormGroup className={(values.expiry !== undefined) ? (errors.expiry ? "has-danger" : "has-success") : ''}>
              <InputMask
                className='form-control'
                mask="99/99"
                placeholder="MM/YY"
                type="tel"
                name="number"
                onChange={e => { setValue('expiry', e.target.value);  triggerValidation("expiry");}}
                onFocus={() => setCardFocus('expiry')}
              />
              </FormGroup>
            </div>
          </Col>
          <Col md="3" className="pt-3">
            <div className="js-form-message mb-6">
              <label className="form-label">CVC *</label>
              <FormGroup className={(values.cvc !== (undefined || '')) ? (errors.cvc ? "has-danger" : "has-success") : ''}>
                <InputMask
                  className='form-control'
                  mask="999"
                  placeholder="CVC"
                  type="tel"
                  name="number"
                  onChange={e => { setValue('cvc', e.target.value);  triggerValidation("cvc");}}
                  onFocus={() => setCardFocus('cvc')}
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
        <p className="pt-5">Seus Dados Pessoais: </p>
        <Row>
          <Col md="6" className="pt-3">
            <div className="js-form-message mb-6">
              <label className="form-label">
                Nome Completo *</label>
              <FormGroup className={(values.name !== (undefined || '')) ? (errors.name ? "has-danger" : "has-success") : ''}>
                <Input
                  aria-label="Jack Wayley"
                  name="name"
                  placeholder="Seu Nome"
                  required=""
                  type="text"
                  onChange={e => { setValue('name', e.target.value);  triggerValidation("name");}}
                  innerRef={register({
                    required: 'Este campo deve ser preenchido',
                    pattern: /.+\s.+/
                  })}
                />
              </FormGroup>
            </div>
          </Col>
          <Col md="6" className="pt-3">
            <div className="js-form-message mb-6">
              <label className="labels" htmlFor="#firstName">
                Digite o seu CPF *
              </label>
              <FormGroup className={(values.cpf !== (undefined || '')) ? (errors.cpf ? "has-danger" : "has-success") : ''}>
                <InputMask
                  className='form-control'
                  mask="999.999.999-99"
                  placeholder="999.999.999-99"
                  type="tel"
                  name="cpf"
                  onChange={e => { setValue('cpf', e.target.value);  triggerValidation("cpf");}}
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
        <Row>
        <Col md="12" className="pt-3">
            <div className="js-form-message mb-6">
              <label className="labels" htmlFor="#firstName">
                Digite o seu Celular *
              </label>
              <FormGroup className={(values.celphone !== (undefined || '')) ? (errors.celphone ? "has-danger" : "has-success") : ''}>
                <InputMask
                  className='form-control'
                  mask="(99) 99999-9999"
                  placeholder="(99) 99999-9999"
                  type="tel"
                  name="cellphone"
                  onChange={e => { setValue('cellphone', e.target.value);  triggerValidation("cellphone");}}
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
        { showModalCode && <Button color="secondary" style={{ width: '100%' }} onClick={toggleModalCode}>
          Código enviado no e-mail.
        </Button>}
        <Button
          color="primary"
          style={{ width: '100%' }}
          type="submit"
        >
          Pagar
        </Button>
      </Form>
      { showModalCode && <Modal isOpen={modalCode} toggle={toggleModalCode}>
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={toggleModalCode}>
            <i className="tim-icons icon-simple-remove"></i>
          </button>
          <h5 className="modal-title">Enviamos um código em seu e-mail:</h5>
        </div>
        <ModalBody>
          <Input
            style={{color: "#222a42"}}
            name="code"
            placeholder="Insira seu código aqui"
            type="text"
            value={subscriber.code}
            onChange={e => { setCodeSubscriber( e.target.value); }}
          />
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={toggleModalCode}>
                Salvar e Fechar.
            </Button>
        </ModalFooter>
      </Modal> }
    </div>
  )
}

export default CreditCardForm;
