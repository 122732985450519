import React from 'react';
import {Button, Card, CardBody, CardFooter, CardTitle, Col} from "reactstrap";

const MyComponent = ({ title, subtitle, content, buttonTitle, icon, link}) => {
  return (
    <Card className="bg-default">
      <CardBody>
        <h6 className="category-social">
          <i className={icon} />
          {title}
        </h6>
        <CardTitle tag="h4">
          <a href={link} onClick={e => e.preventDefault()}>
            {subtitle}
          </a>
        </CardTitle>
        <p className="card-description">
          {content}
        </p>
        <CardFooter className="text-center">
          <Button
            className="btn-round"
            color="default"
            href={link}
          >
            {buttonTitle}
          </Button>
        </CardFooter>
      </CardBody>
    </Card>
  );
};

export default MyComponent;
