import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Table, Button} from "reactstrap";

const InstagramAccountsList = () => {
    const dispatch = useDispatch();
    const instagramAccounts = useSelector( state => state.influencer.instagram_accounts);

    const deleteAccount = (id) => {
        dispatch({ type: 'influencer/removeInstagramAccount', payload: { instagram_account_id: id } })
    }
    return (
        <div>
            <Table responsive>
                <thead>
                <tr>
                    <th className="text-center">#</th>
                    <th className="text-center">Instagram</th>
                    <th className="text-center">Data de Criação</th>
                    <th className="text-right">Apagar</th>
                </tr>
                </thead>
                <tbody>
                { instagramAccounts.map( i =>
                    <tr key={`instagram-accounts-${Math.random()}`}>
                        <td>{i.id}</td>
                        <td>{i.username}</td>
                        <td>{i.created_at}</td>
                        <td><Button onClick={() => deleteAccount(i.id)}>apagar</Button></td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    );
};

export default InstagramAccountsList;
