import {http} from '../../lib/http';

export const auth = {
  state: {
    authToken: null,
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    saveAuthToken(state, payload) {
      return  { ...state, authToken: {...payload} }
    },
    resetAuthToken(state, payload) {
      return  { ...state, authToken: null}
    }
  },
  effects: dispatch => ({
    async saveAuthTokenAsync(payload, rootState) {
      dispatch.auth.saveAuthToken(payload)
    },
  }),
}