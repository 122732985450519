import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {
    Card
} from 'reactstrap'
import styled from 'styled-components'
import {useDispatch, useSelector} from "react-redux";
import Lottie from "react-lottie";

const videoProcessingAnimation = require('assets/lottie/33-video-cam.json');

const UploadVideo = styled.div({
    background: '#000000',
    height: '200px',
    width: '300px',
    marginBottom: '20px'
});

const VideoForm = ({album_id}) => {
    const dispatch = useDispatch();
    const videoProcessing =  useSelector( state => state.albums.video_uploading )

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            const formData = new FormData();
            formData.append('raw_file', file, file.filename);
            formData.append('album_id', album_id);
            formData.append('name', 'video');
            formData.append('description', 'description');

            dispatch({ type: 'albums/createVideo', payload: formData })
          })
      }, [])
      const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    return (
        <>
            {!videoProcessing && <UploadVideo {...getRootProps()}>
            <input {...getInputProps()} />
            <div style={styles.videoMessage}>{
                isDragActive ?
                <p>Drop the files here ...</p> :
                <p>Drag 'n' drop some files here, or click to select files</p>
            }</div>
        </UploadVideo>}
            {videoProcessing && <div>
                <Lottie options={{
                    loop: true,
                    autoplay: true,
                    animationData: videoProcessingAnimation,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                        height={200}
                        width={200}
                        isStopped={false}
                        isPaused={false}/>
            <p>Há um video sendo processado</p>
        </div>}
        </>
    );
};

const styles = {
    videoMessage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center'
    }
}

export default VideoForm;
