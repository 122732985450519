import React from 'react';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import axios from 'axios';
import { Media } from 'reactstrap';

const ProfileItem = ({image, username}) => {
  return (
    <div style={{display: 'flex', alignContent: 'center', alignItems: 'center'}}>
      <div className="avatar">
          <Media
            alt="..."
            className="img-raised"
            src={image}
          />
      </div>
      @{username}
    </div>
  )
}


export default class SearchProfile extends React.Component {
  state = {
    isLoading: false,
    options: [],
    query: '',
  };

  _cache = {};

  render() {
    const {valueChange, oldValue} = this.props
    return (
      <AsyncTypeahead
        {...this.state}
        searchText="Buscando perfil..."
        id="async-pagination-example"
        labelKey="username"
        minLength={2}
        onChange={e => valueChange(e)}
        onInputChange={this._handleInputChange}
        onSearch={this._handleSearch}
        paginate
        placeholder={ oldValue || "Digite o seu Instagram"}
        renderMenuItemChildren={(option, props) => (
          <ProfileItem image={option.image} username={option.username} />
        )}
        useCache={false}
      />
    );
  }

  _handleInputChange = (query) => {
    this.setState({ query });
  }

  _handleSearch = (query) => {
    if (this._cache[query]) {
      this.setState({ options: this._cache[query].options });
      return;
    }

    this.setState({ isLoading: true });
    axios.get('https://api.enjoy.fans/search_instagram', {params: { username_search: query}})
      .then((resp) => {
        this.setState({
          isLoading: false,
          options: resp.data,
        });
      });
  }
}
