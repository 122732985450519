import { init } from '@rematch/core'
import * as models from './models'
import createRematchPersist from '@rematch/persist'

const persistPlugin = createRematchPersist({
    whitelist: ['influencer', 'subscriber', 'auth'],
    throttle: 100,
    version: 1,
})

const store = init({
	plugins: [persistPlugin],
	models,
})

export const { dispatch } = store
export default store
