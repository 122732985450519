import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import Album from "./Album";

const Albums = () => {
    const dispatch = useDispatch();
    const albums = useSelector(state => state.albums.albums)

    useEffect(() => {
        dispatch({ type: 'albums/getAlbums' })
    }, [])
    return (
        <div >
            {albums.map(album => <Album key={`album-${Math.random()}`} album={album}/> )}
        </div>
    );
};

export default Albums;

