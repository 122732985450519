import React from 'react';
import styled from "styled-components";
import ReactPlayer from 'react-player/lazy'

const VideoDiv = styled.div({
    height: '170px',
    width: '240px',
    marginBottom: '10px'
});

const Video = ({video_url, thumb, status = "published", width, height}) => {
    return (
        <VideoDiv style={{ width: (width || 240), height: (height || 170)}}>
            <ReactPlayer
                url={video_url}
                width={width || 240}
                height={height || 170}
                controls
                light={thumb}
            />
        </VideoDiv>
    );
};

export default Video;
