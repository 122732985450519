import React, {Fragment, useEffect, useState} from 'react';
import { useDispatch } from 'react-redux'
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import Button from "reactstrap/es/Button";

import InstagramForm from "../closeFriends/InstagramForm";
import CloseFriendsPlans from "../closeFriends/CloseFriendsPlans";

const InfluencerCloseFriends = () => {
    const [tab, setTab] = useState('plans');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: 'influencer/listInstagramAccounts' })
    }, [])
    return (
        <Fragment>
            <Row>
                <Col>
                    <Button
                        color="info"
                        className="animation-on-hover"
                        style={{width: '100%'}}
                        disabled={tab==='plans'}
                        onClick={() => setTab('plans')}
                    >Habilite em seus Planos</Button>
                </Col>
                <Col>
                    <Button
                        color="info"
                        className="animation-on-hover"
                        style={{width: '100%'}}
                        disabled={tab==='instagramForm'}
                        onClick={() => setTab('instagramForm')}
                    >Adicione seu Instagram</Button>
                </Col>
            </Row>
            { tab === 'plans' && <CloseFriendsPlans />}
            { tab === 'instagramForm' && <InstagramForm/>}
        </Fragment>
    );
};

export default InfluencerCloseFriends;
