import React from 'react';
import { Card, CardBody, CardHeader, CardImg, CardSubtitle, CardText, CardTitle} from "reactstrap";

const InfluencerCard = ({subscription}) => {
    return (
        <Card className="mt-2" style={{width: 300, padding: 10, alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex'}}>
            <CardHeader>
                <CardImg style={{ maxWidth: 100, maxHeight: 100, borderRadius: 50 }} src={subscription.influencer.image} alt="Card image cap" />
            </CardHeader>
            <CardBody>
                <CardTitle>@{subscription.influencer.instagram}</CardTitle>
                <CardSubtitle style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{subscription.plan.name}</CardSubtitle>
                <CardText>{subscription.plan.description}</CardText>
            </CardBody>
        </Card>
    );
};

export default InfluencerCard;
