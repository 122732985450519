import React from 'react';
import styled from "styled-components";
import ReactHLS from 'react-hls'
import ReactPlayer from 'react-player/lazy'
import {Badge} from "reactstrap";

const VideoDiv = styled.div({
    height: '170px',
    width: '240px',
    marginBottom: '10px'
});

const Video = ({video_url, thumb, status = "published"}) => {
    return (
        <VideoDiv>
            <ReactPlayer
                url={video_url}
                width={240}
                height={135}
                controls
                light={thumb}
            />
        </VideoDiv>
    );
};

export default Video;
