import React, {useEffect, useState} from 'react';
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from 'react-redux'
import InputMask from 'react-input-mask';
// reactstrap components
import {
  Button,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup
} from "reactstrap";
import { useHistory } from "react-router-dom";
import SearchProfile from '../../components/SearchProfile'

// toast
import { ToastContainer } from 'react-toastify';

export default function InfluencerForm({ influencer }) {
  const { handleSubmit, register, control, errors, setValue, getValues, triggerValidation } = useForm();
  register({ name: 'cpf' }, { required: 'Este campo deve ser preenchido', pattern: /[0-9\.-]{13}/ });
  register({ name: 'phone' }, { required: 'Este campo deve ser preenchido', pattern: /.{11}[0-9]{4}/ });
  register({ name: 'instagram' }, { required: 'Este campo deve ser preenchido'});

  const dispatch = useDispatch();
  let history = useHistory();

  const onSubmit = values => {
    dispatch({ type: 'influencer/updateProfile', payload: values })
  };

  const values = getValues();

  const [name, setName] = useState(influencer.name);
  const [email, setEmail] = useState(influencer.email);
  const [cpf, setCpf] = useState(influencer.cpf);
  const [phone, setPhone] = useState(influencer.phone);
  const [instagram, setInstagram] = useState(influencer.instagram)
  const [description, setDescription] = useState(influencer.description)

  useEffect(() => {
    setValue('name', name);
    setValue('email', email);
    setValue('cpf', cpf);
    setValue('phone', phone);
    setValue('instagram', instagram);
    setValue('description', description);
  }, [name, email, cpf, phone, instagram, description])

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="js-validate">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Container style={{paddingTop: 50}}>
        <h3 className="title">Altere seus dados:</h3>
        <Row>
          <Col md="6">
            <label className="labels">
              Nome Completo <span className="text-danger">*</span>
            </label>
            <FormGroup className={(values.name !== undefined ) ? (errors.name ? "has-danger" : "has-success") : ''}>
                <Input
                  aria-label="Jack Wayley"
                  name="name"
                  placeholder="Seu Nome"
                  required=""
                  type="text"
                  value={name}
                  onChange={e => { setName(e.target.value);  triggerValidation("name");}}
                  innerRef={register({
                    required: 'Este campo deve ser preenchido',
                    pattern: /.+\s.+/
                  })}
                />
              </FormGroup>
          </Col>
          <Col md="6">
            <label className="labels">
              CPF <span className="text-danger">*</span>
            </label>
            <FormGroup className={(values.cpf !== (undefined || '')) ? (errors.cpf ? "has-danger" : "has-success") : ''}>
                <InputMask
                  className='form-control'
                  mask="999.999.999-99"
                  placeholder="999.999.999-99"
                  type="tel"
                  name="cpf"
                  value={ cpf }
                  disabled={true}
                />
              </FormGroup>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md="6">
            <label className="labels">
              Instagram <span className="text-danger">*</span>
            </label>
            <FormGroup className={(values.instagram !== (undefined || '')) ? (errors.instagram ? "has-danger" : "has-success") : ''}>
              <SearchProfile oldValue={instagram} valueChange={instagram => { setValue('instagram', instagram[0] ); triggerValidation('instagram')}}/>
            </FormGroup>
          </Col>
          <Col md="6">
            <div className="js-form-message mb-6">
              <label className="labels">
              Celular<span className="text-danger">*</span>
              </label>
              <FormGroup className={(values.phone !== (undefined || '')) ? (errors.phone ? "has-danger" : "has-success") : ''}>
                <InputMask
                  className='form-control'
                  mask="(99) 99999-9999"
                  placeholder="(99) 99999-9999"
                  type="tel"
                  name="phone"
                  value={phone}
                  onChange={e => { setPhone(e.target.value);  triggerValidation("phone");}}
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md="6">
            <div className="js-form-message mb-6">
              <label className="labels">
                Email{" "}
                <span className="text-danger">*</span>
              </label>
              <FormGroup className={(values.email !== (undefined || '')) ? (errors.email ? "has-danger" : "has-success") : ''}>
                <Input
                  aria-label="jane@doe.com"
                  name="email"
                  placeholder="jane@doe.com"
                  required=""
                  type="email"
                  value={email}
                  innerRef={register({
                    required: 'Este campo deve ser preenchido',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                    }
                  })}
                  onChange={e => { setEmail(e.target.value);  triggerValidation("email");}}
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md="12">
            <label className="labels">
              Descrição:<span className="text-danger">*</span>
            </label>
            <FormGroup rows={5} style={{maxHeight: 200}} className={(values.name !== undefined ) ? (errors.name ? "has-danger" : "has-success") : ''}>
                <Input
                  aria-label="Jack Wayley"
                  name="description"
                  placeholder="Este é o texto de venda, o texto que aparece para o usuário na hora de assinar o seu plano."
                  required=""
                  type="textarea"
                  style={{maxHeight: 200}}
                  rows={5}
                  value={description}
                  onChange={e => { setDescription(e.target.value);  triggerValidation("description");}}
                  innerRef={register({
                    required: 'Este campo deve ser preenchido',
                    pattern: /.+\s.+/
                  })}
                />
              </FormGroup>
          </Col>
        </Row>
        <Button className="btn-round" style={{float:"right"}} type="submit" color="primary">
          Confirmar Código e Salvar
        </Button>
      </Container>
    </Form>
  )
}
