import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Badge, Table} from "reactstrap";

const CloseFriendsPlans = () => {
    const plans = useSelector( state => state.influencer.plans );
    const instagramAccounts = useSelector( state => state.influencer.instagram_accounts);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch.influencer.listPlans();
    }, [])

    const addInstagramToPlan = (instagramAccountId, PlanId) => {
        console.log(instagramAccountId, PlanId)
        dispatch.influencer.addPlanInstagramAccount({
            instagram_account_id: instagramAccountId,
            plan_id: PlanId
        })
    }
    const removeInstagramToPlan = (instagramAccountId, PlanId) => {
        console.log(instagramAccountId, PlanId)
        dispatch.influencer.removePlanInstagramAccount({
            instagram_account_id: instagramAccountId,
            plan_id: PlanId
        })
    }

    const InstagramAccountPlanButton = ( { instagramAccount, plan }) => {
        let hasPlan = false;
        console.log('lll', instagramAccount.plans.filter( ip => ip.id === plan.id ));

        if(instagramAccount.plans.find( ip => ip.id === plan.id )){

            hasPlan = true
        }

        return <Badge
            color={ hasPlan ? 'success' : 'danger'}
            style={{cursor: 'pointer'}}
            onClick={() => hasPlan ? removeInstagramToPlan(instagramAccount.id, plan.id) : addInstagramToPlan(instagramAccount.id, plan.id)} >
                @{instagramAccount.username}
        </Badge>
    }

    return (
        <div>
            <h3 className="title">Planos:</h3>
            <Table responsive>
                <thead>
                <tr>
                    <th className="text-center">#</th>
                    <th className="text-center">Nome</th>
                    <th className="text-center">Dias</th>
                    <th className="text-right">Dias de Trial</th>
                    <th className="text-right">Valor</th>
                    <th className="text-center">Instagram Close Friends</th>
                </tr>
                </thead>
                <tbody>
                { (plans || []).map((p) =>
                    <tr key={`close-friends-plans-${Math.random()}`}>
                        <td className="text-center">{p.external_id}</td>
                        <td>{p.name}</td>
                        <td className="text-center">{p.days}</td>
                        <td className="text-right">{p.trial_days}</td>
                        <td className="text-right">
                            R$ {p.amount}
                        </td>
                        <td>
                            { instagramAccounts.map( i => <div key={`instagram-plans-${Math.random()}`}>
                                <InstagramAccountPlanButton instagramAccount={i} plan={p}/>
                            </div>) }
                        </td>
                    </tr>)
                }
                </tbody>
            </Table>
        </div>
    )
};

export default CloseFriendsPlans;
