import React from 'react';
import Zuck from 'zuck.js'

const timestamp = function() {
  let timeIndex = 0;
  const shifts = [35, 60, 60 * 3, 60 * 60 * 2, 60 * 60 * 25, 60 * 60 * 24 * 4, 60 * 60 * 24 * 10];

  const now = new Date();
  const shift = shifts[timeIndex++] || 0;
  const date = new Date(now - shift * 1000);

  return date.getTime() / 1000;
};

class Stories extends React.Component {
  constructor(props){
    super(props);

    let videos = [];

    props.plan.albums.map( a => {
      a.videos.map(v => videos =  [...videos, [v.id, "image", 0, v.urls.story, v.urls.story, `/my-account/subscription/${this.props.subscription.subscription.id}/${a.id}/${v.id}`, "ir para o video", false, timestamp()]])
    })

    this.storiesElement = null;
    this.storiesApi = null;

    this.state = {
      stories: [
        Zuck.buildTimelineItem(
          this.props.subscription.influencer.instagram,
          "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/users/1.jpg",
          this.props.subscription.influencer.instagram,
          "https://ramon.codes",
          timestamp(),
          videos
        )
      ]
    }
  }

  componentDidMount() {
    console.log(this.props)
    let currentSkin = {
      avatars: true,
      list: false,
      autoFullScreen: false,
      cubeEffect: true,
      paginationArrows: false
    }; // from demo

    this.storiesApi = new Zuck(this.storiesElement, {
      backNative: true,
      previousTap: true,
      skin: 'Snapgram',
      autoFullScreen: false,
      avatars: true,
      paginationArrows: false,
      list: false,
      cubeEffect: true,
      localStorage: true,
      stories: this.state.stories,
      reactive: true,
      callbacks: {
        onDataUpdate: function (currentState, callback) {
          this.setState(state => {
            state.stories = currentState;

            return state;
          }, () => {
            callback();
          });
        }.bind(this)
      }
    });
  }

  render() {
    const timelineItems = []

    this.state.stories.forEach((story, storyId) => {
      const storyItems = [];

      story.items.forEach((storyItem) => {
        storyItems.push(
          <li key={storyItem.id} data-id={storyItem.id} data-time={storyItem.time} className={(storyItem.seen ? 'seen' : '')}>
            <a href={storyItem.src} data-type={storyItem.type} data-length={storyItem.length} data-link={storyItem.link} data-linkText={storyItem.linkText}>
              <img src={storyItem.preview} />
            </a>
          </li>
        );
      });

      let arrayFunc = story.seen ? 'push' : 'unshift';
      timelineItems[arrayFunc](
        <div className={(story.seen ? 'story seen' : 'story')} key={storyId} data-id={storyId} data-last-updated={story.lastUpdated} data-photo={story.photo}>
          <a className="item-link" href={story.link}>
                  <span className="item-preview">
                    <img src={story.photo} />
                  </span>
            <span className="info" itemProp="author" itemScope="" itemType="http://schema.org/Person">
                    <strong className="name" itemProp="name">{story.name}</strong>
                    <span className="time">{story.lastUpdated}</span>
                  </span>
          </a>

          <ul className="items">
            {storyItems}
          </ul>
        </div>
      );
    });

    return (
      <div>
        <div ref={node => this.storiesElement = node}  id="stories-react" className="storiesWrapper">
          {timelineItems}
        </div>
      </div>
    );
  }
}

export default Stories;
