import {http} from '../../lib/http';

export const albums = {
    state: {
        albums: [],
        loading: false,
        video_loading: false,
        video_uploading: false

    }, // initial state
    reducers: {
        setAlbums(state, payload){
            return { ...state, albums: payload }
        },
        setLoading(state, payload){
            return { ...state, loading: !!payload }
        },
        setVideoUploading(state, payload){
            return { ...state, video_uploading: !!payload }
        }
    },
    effects: dispatch => ({
        async getAlbums(payload, rootState){
            dispatch.albums.setLoading(true);
            http.get(`album/get_albums`)
                .then( albums => {
                    dispatch.albums.setAlbums(albums);
                    dispatch.albums.setLoading(false);
                })
                .catch( () => {
                    console.error("error on get_albums")
                    dispatch.albums.setLoading(false);
                });
        },
        async getAlbumVideos(payload, rootState){
            dispatch.albums.setLoading(true);
            http.get(`album/get_album_videos?album_id=${payload.album_id}`)
                .then( albums => {
                    dispatch.albums.setAlbums(albums);
                    dispatch.albums.setLoading(false);
                })
                .catch( () => {
                    console.error("error on get_albums")
                    dispatch.albums.setLoading(false);
                });
        },
        async createAlbum(payload, rootState){
            dispatch.albums.setLoading(true);
            http.post(`album/create_album`, payload)
                .then( () => {
                    dispatch.albums.getAlbums();
                    dispatch.albums.setLoading(false);
                })
                .catch( () => {
                    console.error("error on get_albums");
                    dispatch.albums.setLoading(false);
                });
        },
        async createVideo(payload, rootState){
            dispatch.albums.setVideoUploading(true);
            http.post(`album/create_video`, payload)
                .then( () => {
                    dispatch.albums.getAlbums();
                    dispatch.albums.setVideoUploading(false);
                })
                .catch( () => {
                    console.error("error on get_albums");
                    dispatch.albums.setVideoUploading(false);
                });
        },
        async removeVideo(payload, rootState){
            http.delete(`album/delete_video`, { params: payload })
                .then( () => {
                    dispatch.albums.getAlbums();
                })
                .catch( () => {
                    console.error("error on get_albums");
                });
        },
        async togglePlan(payload, rootState){
            if(payload.action === 'add'){
                http.post(`album/add_plan`, payload)
                    .then( () => {
                        dispatch.albums.getAlbums();
                    })
                    .catch( () => {
                        console.error("error on get_albums");
                    });
            }
            if(payload.action === 'remove'){
                http.post(`album/remove_plan`, payload)
                    .then( () => {
                        dispatch.albums.getAlbums();
                    })
                    .catch( () => {
                        console.error("error on get_albums");
                    });
            }

        }
    }),
}
