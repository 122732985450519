import axios from 'axios';
import { dispatch } from '../rematch/index'
import { getAuthHeaders } from './queries/getAuthHeaders';
import { Auth } from 'aws-amplify';
// import { storeAuthToken } from 'redux/actions';
// import { store } from 'redux/store';

const API_URL = 'https://api.enjoy.fans/api/v1/';

const AUTH_HEADER_KEYS = ['access-token', 'token-type', 'client', 'expiry', 'uid']
const http = axios.create({
  baseURL: API_URL
});

const http_without_auth = axios.create({
  baseURL: API_URL
});

const http_with_cognito = axios.create({
  baseURL: API_URL
});

function onResponseSuccess(response) {
  setAuthHeadersInLocalStorage(response.headers);
  const { data } = response;
  return data;
}

function onResponseError(error) {
  const message = parseErrorMessage(error.response);
  if (message) {
    error.message = message;
  }
  return Promise.reject(error);
}

async function onRequestSuccess(config) {
  config.headers = { ...config.headers, ...getAuthHeaders()};
  return config;
}

function parseErrorMessage(errResponse) {
  //dispatch.influencer.logout();
  return errResponse && errResponse.data ? errResponse.data.errors : '';
}

function setAuthHeadersInLocalStorage(headers) {
  const authToken = {};
  AUTH_HEADER_KEYS.forEach((key) => {
    if(headers[key] !== "") authToken[key] = headers[key];
  });
  dispatch.auth.saveAuthTokenAsync(authToken)
}

async function setCognitoAccessToken(config){
  const user = await Auth.currentAuthenticatedUser();
  if (user.signInUserSession.accessToken.jwtToken){
    config.headers = { ...config.headers, accessToken: user.signInUserSession.accessToken.jwtToken};
  }
  return config;
}

http.interceptors.request.use(onRequestSuccess);
http.interceptors.response.use(onResponseSuccess, onResponseError);

http_with_cognito.interceptors.request.use(setCognitoAccessToken)

export { http, http_without_auth, http_with_cognito };
