import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.jsx";
import InfluencerForm from "./InfluencerForm.jsx";
import defaultAvatar from "assets/img/placeholder.jpg";

// toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class InfluencerRegister extends React.Component {
  state = { 
    instagram: null,
    instagramImage: null
  };
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("checkout-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("checkout-page");
  }
  toggleTabs = (e, stateName, index) => {
    e.preventDefault();
    this.setState({
      [stateName]: index
    });
  };

  render() {
    const { instagram, instagramImage } = this.state;

    return (
      <div className="login-page">
        <ToastContainer 
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <div className="squares square1" />
        <div className="squares square2" />
        <div className="squares square3" />
        <div className="squares square5" />
        <ColorNavbar />
        <div className="wrapper" ref="wrapper">
          <div className="section">
            <Container style={{flex: 1, marginTop: '10%'}} >
              <Row  style={{display: 'flex', flex: 1, alignItems: 'center', alignContent: 'center'}}>
              <Col lg="8">
                  <InfluencerForm instagram={ (instagram) => { this.setState({instagram}); console.log(instagram)}} instagramImage={ (instagramImage) => this.setState({instagramImage}) } />
                </Col>
                <Col lg="4">
                <Card className="card-profile profile-bg">
                <CardBody>
                      <div className="card-avatar">
                        <a href="#" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img img-raised"
                            src={instagramImage || defaultAvatar}
                          />
                        </a>
                      </div>
                    <Col className="align-self-center" md={{size: 10, offset: 1}}>
                      <CardTitle tag="h3">@{instagram || "influencer" }</CardTitle>
                      <h6 className="category text-primary">Enjoy.fans</h6>
                      <p className="card-description">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                      </p>
                      </Col>
                      </CardBody>
                      <CardFooter></CardFooter> 
                    </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

export default InfluencerRegister;
