import React, {Fragment, useState} from 'react';
import BankAccount from "../finance/BankAccount";
import Transactions from "../finance/Transactions";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import Button from "reactstrap/es/Button";

function InfluencerFinance(props) {
  const [tab, setTab] = useState('transaction')
  return (
      <Fragment>
        <Row>
          <Col>
            <Button
                color="info"
                className="animation-on-hover"
                style={{width: '100%'}}
                disabled={tab==='transaction'}
                onClick={() => setTab('transaction')}
            >Transações</Button>
          </Col>
          <Col>
            <Button
                color="info"
                className="animation-on-hover"
                style={{width: '100%'}}
                disabled={tab==='bankAccount'}
                onClick={() => setTab('bankAccount')}
            >Conta Bancária</Button>
          </Col>
        </Row>
        { tab === 'bankAccount' && <BankAccount />}
        { tab === 'transaction' && <Transactions/>}
      </Fragment>
  );
}

export default InfluencerFinance;
