import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Alert,
  CardFooter
} from "reactstrap";
import Lottie from 'react-lottie';
import SubscriberNavbar from "components/Navbars/SubscriberNavbar.jsx";
import CreditCardForm from "views/CloseFriends/CreditCardForm.jsx";
import BoletoForm from "views/CloseFriends/BoletoForm.jsx";
import { useDispatch, useSelector } from 'react-redux';

const  Payment = ({influencer_name}) => {
  const dispatch = useDispatch();
  const [creditCard, setCreditCard] = useState(true)
  const [plan, setPlan] = useState(false)

  const influencer_param = influencer_name;

  const influencer = useSelector(state => state.subscriber.influencer)
  const paymentMessage = useSelector(state => state.payment.message)
  const paymentStatus = useSelector(state => state.payment.status)

  const processingPayment = require('assets/lottie/18176-card-payment-in-process.json');
  const successPayment = require('assets/lottie/10447-payment-success.json');
  const errorPayment = require('assets/lottie/10448-payment-failed-error.json');

  useEffect(()=>{
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    document.body.classList.add("account-settings");
    dispatch({ type: 'subscriber/getInfluencer', payload: influencer_param })
    dispatch({ type: 'payment/clean', payload: influencer_param })
  },[]);

  useEffect(() => {
    dispatch({ type: 'subscriber/setPlan', payload: plan })
  },[plan])

  return (
    <>
    <SubscriberNavbar />
    <div className="wrapper">
      <div className="section">
      {!plan && <div className="pricing-5" id="pricing-5">
        <Container>
          <Row style={{alignContent: 'center', alignItems: 'center', justifyContent: 'center'}}>
            { influencer && influencer.plans && influencer.plans.map(plan => <Col key={Math.random()} md="6">
              <Card className="card-pricing card-plain card-primary">
                <CardBody>
                  <h2 className="title">{plan.name}</h2>
                  <h5 className="description">
                    R${plan.amount /100} <small>/ mês</small>
                  </h5>
                  <p>
                    {plan.description}
                  </p>
                  <Button
                    className="btn-round"
                    color="info"
                    onClick={e => setPlan(plan)}
                  >
                    Escolher este plano
                  </Button>
                </CardBody>
              </Card>
            </Col> )}
          </Row>
        </Container>
      </div> }
        { plan && <Container style={{minWidth: 350}}>
          <Row>
            <Col className="ml-auto mr-auto" md="6">
            { <Card className="card-profile profile-bg" style={{ display: (paymentStatus === "ready" ? 'block' : 'none' ) }}>
                <CardBody>
                  <h3>Pagamento Assinatura:</h3>
                  <p>{ plan.name }: R${ plan.amount/100 }/mensal</p>
                  <p>{ plan.description }</p>
                  <Row>
                    <Col md="6">
                    <Button
                        disabled={creditCard}
                        color="success"
                        href="#cf"
                        style={{width: '100%'}}
                        onClick={e => setCreditCard(true) }
                      >
                        Cartão de Crédito
                      </Button>
                    </Col>
                    <Col md="6">
                      <Button
                        disabled={!creditCard}
                        color="success"
                        href="#cf"
                        style={{width: '100%'}}
                        onClick={e => setCreditCard(false) }
                      >
                        Boleto
                      </Button>
                    </Col>
                  </Row>
                  { creditCard && <CreditCardForm /> }
                  { !creditCard && <BoletoForm /> }
                </CardBody>
                { paymentMessage && <CardFooter>
                <Alert style={{marginVertical: 20 }} color="danger">{paymentMessage}</Alert>
                </CardFooter> }
              </Card> }
              { (paymentStatus === "processing" ) && <Card className="card-profile profile-bg">
                <CardBody>
                <h3>Processando Pagamento:</h3>
                <Lottie options={{
                  loop: true,
                  autoplay: true,
                  animationData: processingPayment,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
                  height={200}
                  width={200}
                  isStopped={false}
                  isPaused={false}/>
                </CardBody></Card> }
              { (paymentStatus === "success" ) && <Card className="card-profile profile-bg">
                <CardBody>
                <h3>Pagamento processado com sucesso!</h3>
                <Lottie options={{
                  loop: false,
                  autoplay: true,
                  animationData: successPayment,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
                  height={200}
                  width={200}
                  isStopped={false}
                  isPaused={false}/>
                <p>Aguarde nossas instruções de como prosseguir pelo e-mail cadastrado.</p>
                </CardBody></Card> }
              { (paymentStatus === "error" ) &&  <Card className="card-profile profile-bg">
                <CardBody>
                <h3>Houve um erro no processamento do pagamento...</h3>
                <Lottie options={{
                  loop: false,
                  autoplay: true,
                  animationData: errorPayment,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
                  height={200}
                  width={200}
                  isStopped={false}
                  isPaused={false}/>
                  <p>Infelizmente não conseguimos processar o seu pagamento, tente mais uma vez.</p>
                  <Col md="12">
                    <Button
                        color="success"
                        href="#cf"
                        style={{width: '100%'}}
                        onClick={() => dispatch({ type: 'payment/setStatus', payload: "ready" }) }
                      >
                        Tentar novamente
                      </Button>
                    </Col>
                </CardBody></Card> }
            </Col>
          </Row>
        </Container> }
      </div>
    </div>
  </>
  )
}

export default Payment;
